import React from 'react';
import RegistrationCard from './helpers/RegistrationCard';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import StyledTextField from './helpers/StyledTextField';
import { Grid, Typography } from '@material-ui/core';
import SpacerVertical from './helpers/SpacerVertical';
import * as Yup from 'yup';
import Button from './helpers/Button';
import GlimraStore from '../store/GlimraStore';
import { snackRef } from './Snack';
import { extractErrorMessage } from '../helpers/apiUtils';

const CompanyRegistrationForm = ({ onRegister, initalValues }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: initalValues.name || '',
      orgnr: initalValues.orgnr || '',
      address: initalValues.address || '',
      zipnr: initalValues.zipnr || '',
      city: initalValues.city || '',
      invoice_email: initalValues.invoice_email || '',
      mobile: initalValues.mobile || '',
    },
    validationSchema: Yup.object().shape({
      userType: Yup.string(),
      name: Yup.string().required(t('companyRegistrationForm.nameRequired')),
      orgnr: Yup.string().required(t('companyRegistrationForm.orgnrRequired')),
      address: Yup.string().required(t('companyRegistrationForm.addressRequired')),
      zipnr: Yup.string().required(t('companyRegistrationForm.zipnrRequired')),
      city: Yup.string().required(t('companyRegistrationForm.cityRequired')),
      invoice_email: Yup.string()
        .required(t('companyRegistrationForm.invoiceEmailRequired'))
        .email(t('companyRegistrationForm.invoiceEmailFormat')),
      mobile: Yup.string().required(t('companyRegistrationForm.mobileRequired')),
    }),
    onSubmit: (values) => {
      GlimraStore.checkCompanyData(values)
        .then(() => onRegister(values))
        .catch((error) => {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('companyRegistrationForm.createCompanyError.title'),
            message: extractErrorMessage(
              error,
              t('companyRegistrationForm.createCompanyError.message')
            ),
          });
        });
    },
  });

  return (
    <Grid item xs={12} md={8}>
      <RegistrationCard container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{t('companyRegistrationForm.card1')}</Typography>
          <Typography variant="h5">{t('companyRegistrationForm.card1Title')}</Typography>
          <SpacerVertical size="small" />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            id="name"
            label={t('companyRegistrationForm.companyNameLabel')}
            value={formik.values.name}
            error={formik.errors.name}
            touched={formik.touched.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            id="orgnr"
            label={t('companyRegistrationForm.orgnrLabel')}
            value={formik.values.orgnr}
            error={formik.errors.orgnr}
            touched={formik.touched.orgnr}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            id="address"
            label={t('companyRegistrationForm.addressLabel')}
            value={formik.values.address}
            error={formik.errors.address}
            touched={formik.touched.address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledTextField
                id="zipnr"
                label={t('companyRegistrationForm.zipnrLabel')}
                type="number"
                value={formik.values.zipnr}
                error={formik.errors.zipnr}
                touched={formik.touched.zipnr}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                id="city"
                label={t('companyRegistrationForm.cityLabel')}
                value={formik.values.city}
                error={formik.errors.city}
                touched={formik.touched.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            id="invoice_email"
            label={t('companyRegistrationForm.invoiceEmailLabel')}
            type="email"
            value={formik.values.invoice_email}
            error={formik.errors.invoice_email}
            touched={formik.touched.invoice_email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            id="mobile"
            label={t('companyRegistrationForm.mobileLabel')}
            type="phone"
            value={formik.values.mobile}
            error={formik.errors.mobile}
            touched={formik.touched.mobile}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            color="default"
            fullWidth
            disableElevation
          >
            {t('companyRegistrationForm.buttonRegister')}
          </Button>
        </Grid>
      </RegistrationCard>
    </Grid>
  );
};

export default CompanyRegistrationForm;
