import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Tabs, Tab, TextField } from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from './helpers/Loader';
import Button from './helpers/Button';
import CreditCardDialog from '../components/CreditCardDialog';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import SpacerVertical from '../components/helpers/SpacerVertical';
import WhiteSelectField from '../components/helpers/WhiteSelectField';

const PaymentMethod = ({ onCompleteFlow }) => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [addCardDialogOpen, setAddCardDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loading) {
      GlimraStore.fetchPaymentMethod()
        .then((data) => {
          setPaymentMethod(data.results);
          if (onCompleteFlow) {
            onCompleteFlow();
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [loading, onCompleteFlow]);

  const removePaymentMethod = (id) => {
    GlimraStore.deletePaymentMethod(id)
      .then(() => setLoading(true))
      .catch(() => {
        // Error message handled by glimra store
      });
  };

  const stripePaymentMethod = {
    id: paymentMethod?.filter((obj) => {
      return obj.provider === 'Stripe';
    })?.[0]?.id,
    stripe_payment_method_id: paymentMethod?.filter((obj) => {
      return obj.provider === 'Stripe';
    })?.[0]?.stripe_payment_method_id,
    stripe_last4: paymentMethod?.filter((obj) => {
      return obj.provider === 'Stripe';
    })?.[0]?.stripe_last4,
  };

  const invoicePaymentMethod = {
    id: paymentMethod?.filter((obj) => {
      return obj.provider === 'TrustPoint';
    })?.[0]?.id,
    invoice_email: paymentMethod?.filter((obj) => {
      return obj.provider === 'TrustPoint';
    })?.[0]?.invoice_email,
    tp_sendtype: paymentMethod?.filter((obj) => {
      return obj.provider === 'TrustPoint';
    })?.[0]?.tp_sendtype,
    tp_evoiceaddress: paymentMethod?.filter((obj) => {
      return obj.provider === 'TrustPoint';
    })?.[0]?.tp_evoiceaddress,
    tp_evoiceintermediator: paymentMethod?.filter((obj) => {
      return obj.provider === 'TrustPoint';
    })?.[0]?.tp_evoiceintermediator,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <Grid item xs={12}>
        {value === index && (
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            {children}
          </Grid>
        )}
      </Grid>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          item
          xs={12}
          sm={6}
        >
          <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={t('paymentMethodPage.stripe.title')} {...a11yProps(0)} />
              <Tab label={t('paymentMethodPage.invoice.title')} {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <TabPanel value={value} index={0}>
            {stripePaymentMethod.stripe_payment_method_id ? (
              <>
                <Grid item>
                  <Grid item>{t('paymentMethodPage.stripe.cardNumber')}</Grid>
                  <Grid item>
                    {t('paymentMethodPage.stripe.cardNumberBlanked', {
                      lastFour: stripePaymentMethod.stripe_last4,
                    })}
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<DeleteIcon />}
                    onClick={() => removePaymentMethod(stripePaymentMethod.id)}
                  >
                    {t('paymentMethodPage.stripe.buttonRemove')}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button
                    onClick={() => setAddCardDialogOpen(true)}
                    variant="contained"
                    color="default"
                  >
                    {t('paymentMethodPage.stripe.buttonAddCard')}
                  </Button>
                </Grid>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  tp_sendtype: invoicePaymentMethod.tp_sendtype || 'email',
                  invoice_email: invoicePaymentMethod.invoice_email || '',
                  tp_evoiceaddress: invoicePaymentMethod.tp_evoiceaddress || '',
                  tp_evoiceintermediator: invoicePaymentMethod.tp_evoiceintermediator || '',
                }}
                validationSchema={Yup.object({
                  invoice_email: Yup.string().required(
                    t('paymentMethodPage.invoice.invoiceEmailRequired')
                  ),
                  tp_evoiceaddress: Yup.string().when('tp_sendtype', {
                    is: 'evoice',
                    then: Yup.string().required(
                      t('paymentMethodPage.invoice.tpEvoiceAddressRequired')
                    ),
                  }),
                  tp_evoiceintermediator: Yup.string().when('tp_sendtype', {
                    is: 'evoice',
                    then: Yup.string().required(
                      t('paymentMethodPage.invoice.tpEvoiceIntermediatorRequired')
                    ),
                  }),
                })}
                onSubmit={(values) => {
                  const data = {
                    tp_sendtype: values.tp_sendtype,
                    invoice_email: values.invoice_email,
                    tp_evoiceaddress: values.tp_evoiceaddress,
                    tp_evoiceintermediator: values.tp_evoiceintermediator,
                  };
                  GlimraStore.updateTrustpointPaymentMethod(data)
                    .then(() => {
                      setLoading(true);
                      snackRef.current.updateSnack({
                        variant: 'success',
                        title: t(
                          'paymentMethodPage.invoice.updatePaymentMethodInvoiceSuccess.title'
                        ),
                        message: t(
                          'paymentMethodPage.invoice.updatePaymentMethodInvoiceSuccess.message'
                        ),
                      });
                    })
                    .catch((error) => {
                      snackRef.current.updateSnack({
                        variant: 'error',
                        title: t('paymentMethodPage.invoice.updatePaymentMethodInvoiceError.title'),
                        message: extractErrorMessage(
                          error,
                          t('paymentMethodPage.invoice.updatePaymentMethodInvoiceError.message')
                        ),
                      });
                    });
                }}
              >
                {({
                  errors,
                  values,
                  setFieldValue,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                }) => (
                  <>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <WhiteSelectField
                          fullWidth
                          id="tp_sendtype"
                          label={t('paymentMethodPage.invoice.tpSendType')}
                          defaultValue="email"
                          value={values.tp_sendtype}
                          onChange={(e) => {
                            setFieldValue('tp_sendtype', e.target.value, false);
                          }}
                        >
                          <MenuItem key="email" value="email">
                            {t('paymentMethodPage.invoice.tpSendTypeEpost')}
                          </MenuItem>
                          <MenuItem key="evoice" value="evoice">
                            {t('paymentMethodPage.invoice.tpSendTypeEvoice')}
                          </MenuItem>
                        </WhiteSelectField>
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          id="invoice_email"
                          label={t('paymentMethodPage.invoice.invoiceEmail')}
                          value={values.invoice_email}
                          onChange={handleChange}
                          error={touched.invoice_email && errors.invoice_email}
                          helperText={touched.invoice_email && errors.invoice_email}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </Grid>
                      {values.tp_sendtype === 'evoice' && (
                        <Grid item>
                          <TextField
                            required
                            id="tp_evoiceaddress"
                            label={t('paymentMethodPage.invoice.tpEvoiceAddress')}
                            value={values.tp_evoiceaddress}
                            onChange={handleChange}
                            error={touched.tp_evoiceaddress && errors.tp_evoiceaddress}
                            helperText={touched.tp_evoiceaddress && errors.tp_evoiceaddress}
                            onBlur={handleBlur}
                            fullWidth
                          />
                        </Grid>
                      )}
                      {values.tp_sendtype === 'evoice' && (
                        <Grid item>
                          <TextField
                            required
                            id="tp_evoiceintermediator"
                            label={t('paymentMethodPage.invoice.tpEvoiceIntermediator')}
                            value={values.tp_evoiceintermediator}
                            onChange={handleChange}
                            error={touched.tp_evoiceintermediator && errors.tp_evoiceintermediator}
                            helperText={
                              touched.tp_evoiceintermediator && errors.tp_evoiceintermediator
                            }
                            onBlur={handleBlur}
                            fullWidth
                          />
                        </Grid>
                      )}
                      <SpacerVertical />
                      <Grid item container justify={'space-between'}>
                        <Button color="default" variant="contained" onClick={handleSubmit}>
                          {t('settingsPage.updateCompanyButton')}
                        </Button>
                        {invoicePaymentMethod.id && (
                          <Button
                            variant="contained"
                            color="default"
                            startIcon={<DeleteIcon />}
                            onClick={() => removePaymentMethod(invoicePaymentMethod.id)}
                          >
                            {t('paymentMethodPage.stripe.buttonRemove')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Formik>
            </Grid>
          </TabPanel>
        </Grid>
      )}
      <CreditCardDialog
        open={addCardDialogOpen}
        onClose={() => setAddCardDialogOpen(false)}
        onSuccess={() => {
          setLoading(true); // This will trigger a reload of payment options
          setAddCardDialogOpen(false);
        }}
      />
    </>
  );
};

export default PaymentMethod;
