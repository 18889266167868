import React, { useContext, useState, useRef } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import GlimraStore from '../store/GlimraStore';
import Root from '../components/helpers/Root';
import { Box, Container, Grid, withWidth, isWidthDown, Typography } from '@material-ui/core';
import SpacerVertical from '../components/helpers/SpacerVertical';
import InviteUserDialog from '../components/InviteUserDialog';
import { formatDate } from '../helpers/format';
import RemoveUserDialog from '../components/RemoveUserDialog';
import { CompanyContext } from '../store/CompanyContext';
import { path } from 'ramda';
import Pageheader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import Button from '../components/helpers/Button';
import theme from '../styles/theme';
import { useTranslation } from 'react-i18next';

const UserListPage = ({ history, width }) => {
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
  const [removeUserMobile, setRemoveUserMobile] = useState(false);
  const tableRefUsers = useRef(null);
  const tableRefInvites = useRef(null);
  const company = useContext(CompanyContext);
  const { t } = useTranslation();

  const userColumns = [
    {
      title: t('userListPage.columnUserName'),
      field: 'first_name',
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`,
    },
    {
      title: t('userListPage.columnNote'),
      field: 'notes',
      hidden: isWidthDown('sm', width),
    },
    {
      title: t('userListPage.columnNbrWashes'),
      field: 'company_nbr_washes',
      hidden: isWidthDown('sm', width),
    },
    {
      title: t('userListPage.columnAmount'),
      field: 'company_total_amount',
    },
    {
      title: t('userListPage.columnMonthlyMax'),
      field: 'monthly_max',
      hidden: isWidthDown('sm', width),
    },
    {
      title: t('userListPage.columnLatestWash'),
      field: 'last_company_wash',
      render: ({ last_company_wash }) =>
        !!last_company_wash ? formatDate(last_company_wash) : '-',
    },
  ];

  const inviteColumns = [
    {
      title: t('userListPage.columnInviteMobile'),
      field: 'mobile',
    },
    {
      title: t('userListPage.columnRemove'),
      field: 'mobile',
      sorting: false,
      render: (rowData) => (
        <Button
          onClick={(rowdata) => openRemoveUserDialog(rowData.mobile)}
          color="default"
          variant="contained"
        >
          {t('userListPage.columnRemoveButton')}
        </Button>
      ),
    },
  ];

  const fetchUserData = ({ page, pageSize }) => {
    return GlimraStore.listUsers(path(['companyData', 'id'], company), page + 1, pageSize).then(
      ({ data }) => {
        return { data: data.results, page: page, totalCount: data.count };
      }
    );
  };

  const fetchInvitedUsers = ({ orderBy, orderDirection, page, pageSize }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listInvitedUsers(
      path(['companyData', 'id'], company),
      page + 1,
      pageSize,
      order
    ).then(({ data }) => {
      return { data: data.results, page: page, totalCount: data.count };
    });
  };

  const refreshTables = () => {
    tableRefUsers.current && tableRefUsers.current.onQueryChange();
    tableRefInvites.current && tableRefInvites.current.onQueryChange();
  };

  const openRemoveUserDialog = (mobile) => {
    setRemoveUserMobile(mobile);
    setRemoveUserDialogOpen(true);
  };

  return (
    <Root>
      <Pageheader header={t('userListPage.header')} subtitle={t('userListPage.subtitle')} />
      <GreyBox>
        <Container disableGutters>
          <Grid container direction="column">
            <MaterialTable
              components={{
                Container: ({ children }) => <div>{children}</div>,
                Toolbar: (props) => (
                  <Box style={{ padding: theme.spacing(3) }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Box>
                          <Typography variant="h6">{t('userListPage.userTableTitle')}</Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => setInviteUserDialogOpen(true)}
                        >
                          {t('userListPage.inviteColleagueButton')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ),
              }}
              tableRef={tableRefUsers}
              title={t('userListPage.userTableTitle')}
              columns={userColumns}
              data={(query) => fetchUserData(query)}
              minRows={0}
              options={{
                debounceInterval: 350,
                pageSize: 10,
                search: false,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  backgroundColor: theme.palette.grey.glimraLight,
                  fontWeight: theme.typography.fontWeightBold,
                },
              }}
              onRowClick={(e, { uuid }) => history.push(`/users/${uuid}`)}
            />
            <SpacerVertical size="big" />
            <MaterialTable
              tableRef={tableRefInvites}
              title={t('userListPage.inviteesTableList')}
              columns={inviteColumns}
              components={{
                Container: ({ children }) => <div>{children}</div>,
              }}
              data={(query) => fetchInvitedUsers(query)}
              options={{
                debounceInterval: 350,
                pageSize: 10,
                search: false,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  backgroundColor: theme.palette.grey.glimraLight,
                  fontWeight: theme.typography.fontWeightBold,
                },
              }}
            />
          </Grid>
          <InviteUserDialog
            open={inviteUserDialogOpen}
            onClose={() => setInviteUserDialogOpen(false)}
            closeAndRefresh={() => {
              setInviteUserDialogOpen(false);
              refreshTables();
            }}
          />
          <RemoveUserDialog
            open={removeUserDialogOpen}
            mobile={removeUserMobile}
            onClose={() => setRemoveUserDialogOpen(false)}
            onRemove={() => {
              setRemoveUserDialogOpen(false);
              refreshTables();
            }}
          />
        </Container>
      </GreyBox>
    </Root>
  );
};

export default withWidth()(withRouter(UserListPage));
