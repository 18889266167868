import React, { useState } from 'react';
import { withStyles, InputBase, CircularProgress, Grid } from '@material-ui/core';
import Button from '../components/helpers/Button';
import { useTranslation } from 'react-i18next';

const CredentialsInput = withStyles((theme) => ({
  input: {
    borderRadius: 8,
    padding: '9px 12px',
    margin: 10,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey.glimraDark,
    },
  },
}))(InputBase);

const CredentialGridContainer = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))(Grid);

const CredentialsButton = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    padding: '6px 24px',
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  contained: {
    '&$disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'rgba(255, 255, 255, 0.8)',
    },
  },
}))(Button);

const ForgotPasswordContainer = withStyles((theme) => ({
  root: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      justifyContent: 'center',
    },
  },
}))(Grid);

const ForgotPasswordButton = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    fontSize: '0.7rem',
    color: theme.palette.common.white,
  },
}))(Button);

const LoginForm = ({ toggleForgotPassword, login }) => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { t } = useTranslation();

  const handleLogin = () => {
    setLoading(true);
    login(credentials.email, credentials.password).finally(() => setLoading(false));
  };

  const disabled = !loading && (!credentials.password || !credentials.email);

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item>
        <CredentialGridContainer container>
          <Grid item>
            <CredentialsInput
              id="email"
              placeholder={t('loginForm.emailPlaceholder')}
              value={credentials.email}
              onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
            />
          </Grid>
          <Grid item>
            <CredentialsInput
              id="password"
              placeholder={t('loginForm.passwordPlaceholder')}
              type="password"
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
            />
          </Grid>
          <Grid item>
            <CredentialsButton
              disableElevation
              variant="contained"
              color="default"
              type="submit"
              disabled={disabled}
              onClick={handleLogin}
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : t('loginForm.logIn')}
            </CredentialsButton>
          </Grid>
        </CredentialGridContainer>
        <ForgotPasswordContainer container>
          <Grid item>
            <ForgotPasswordButton onClick={toggleForgotPassword}>
              {t('loginForm.forgotPassword')}
            </ForgotPasswordButton>
          </Grid>
        </ForgotPasswordContainer>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
