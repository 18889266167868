import React, { useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import Root from '../components/helpers/Root';
import Typography from '@material-ui/core/Typography';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { CompanyContext } from '../store/CompanyContext';
import { MEMBERSHIP_LEVELS } from '../store/definitions/constants';
import MaterialTable from 'material-table';
import GlimraStore from '../store/GlimraStore';
import { formatDate } from '../helpers/format';
import { withRouter } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import theme from '../styles/theme';
import LevelCards from '../components/LevelCards';
import { useTranslation } from 'react-i18next';

const LevelPage = ({ history }) => {
  const companyData = useContext(CompanyContext).companyData;
  const { t } = useTranslation();

  const columns = [
    {
      title: t('levelPage.columnUser'),
      field: 'user_mobile',
    },
    {
      title: t('levelPage.columnAmount'),
      field: 'amount',
      render: ({ amount, currency }) => `${amount} ${currency}`,
    },
    {
      title: t('levelPage.coulmnSite'),
      field: 'site_name',
    },
    {
      title: t('levelPage.columnCreated'),
      field: 'created',
      render: ({ created }) => formatDate(created),
    },
  ];

  const fetchWashData = ({ orderBy, orderDirection, page, pageSize }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listPayments(companyData.id, page + 1, pageSize, order).then(({ data }) => {
      return { data: data.results, page: page, totalCount: data.count };
    });
  };

  return (
    <Root>
      <PageHeader header={t('levelPage.header')} subtitle={t('levelPage.subtitle')} />
      <GreyBox>
        <Container disableGutters maxWidth={'md'}>
          <Typography variant="h6" noWrap>
            <Box fontWeight="fontWeightBold">
              {t('levelPage.chosenLevel', {
                level: MEMBERSHIP_LEVELS[companyData.membership_level],
              })}
            </Box>
          </Typography>
          <SpacerVertical />

          <LevelCards />

          <SpacerVertical />

          <SpacerVertical size="big" />

          <Typography variant="h6" noWrap>
            <Box fontWeight="fontWeightBold">{t('levelPage.historySectionHeader')}</Box>
          </Typography>

          <MaterialTable
            title={t('levelPage.tableHeader')}
            columns={columns}
            components={{
              Container: ({ children }) => <div>{children}</div>,
              Toolbar: () => null,
            }}
            data={(query) => fetchWashData(query)}
            options={{
              debounceInterval: 350,
              pageSize: 10,
              search: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: theme.palette.grey.glimraLight,
                fontWeight: theme.typography.fontWeightBold,
              },
            }}
            onRowClick={(e, { user }) => {
              history.push(`/users/${user}`);
            }}
          />
        </Container>
      </GreyBox>
    </Root>
  );
};

export default withRouter(LevelPage);
