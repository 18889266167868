import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import Root from '../components/helpers/Root';
import SpacerVertical from '../components/helpers/SpacerVertical';
import Loader from '../components/helpers/Loader';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { formatDate } from '../helpers/format';
import RemoveUserDialog from '../components/RemoveUserDialog';
import MaterialTable from 'material-table';
import { CompanyContext } from '../store/CompanyContext';
import { extractErrorMessage } from '../helpers/apiUtils';
import { snackRef } from '../components/Snack';
import Pageheader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import theme from '../styles/theme';
import Button from '../components/helpers/Button';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UserDetailsPage = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [deletedUser, setDeletedUser] = useState(false);
  const [userData, setUserData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const companyData = useContext(CompanyContext).companyData;
  const { t } = useTranslation();

  const columns = [
    {
      title: t('userDetailsPage.columnAmount'),
      field: 'amount',
      render: ({ amount, currency }) => `${amount} ${currency}`,
    },
    {
      title: t('userDetailsPage.columnSite'),
      field: 'site_name',
    },
    {
      title: t('userDetailsPage.columnCreated'),
      field: 'created',
      render: ({ created }) => formatDate(created),
    },
  ];

  useEffect(() => {
    GlimraStore.getUser(id)
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        const status = error?.response?.status;

        if (status === 404) {
          setDeletedUser(true);
          setLoading(false);
        }
      });
  }, [id]);

  const fetchUserWashData = ({ orderBy, orderDirection, page, pageSize }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listUserPayments(companyData.id, id, page + 1, pageSize, order).then(
      ({ data }) => ({ data: data.results, page: page, totalCount: data.count })
    );
  };

  const UserInformation = () => (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.mobile')}</Box>
            </TableCell>
            <TableCell>
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.note')}</Box>
            </TableCell>
            <TableCell align="right">
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.latestWash')}</Box>
            </TableCell>
            <TableCell align="right">
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.numberOfWashes')}</Box>
            </TableCell>
            <TableCell align="right">
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.totalCost')}</Box>
            </TableCell>
            <TableCell align="right">
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.monthlyCap')}</Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{userData?.mobile}</TableCell>
            <TableCell>{userData?.notes}</TableCell>
            <TableCell align="right">{formatDate(userData?.last_wash)}</TableCell>
            <TableCell align="right">{userData?.company_nbr_washes}</TableCell>
            <TableCell align="right">{userData?.company_total_amount}</TableCell>
            <TableCell align="right">{userData?.monthly_max}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <SpacerVertical size="big" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <Formik
              initialValues={{
                monthlyMax: userData?.monthly_max,
                notes: userData?.notes,
              }}
              validationSchema={Yup.object().shape({
                notes: Yup.string(t('userDetailsPage.notesFormat')),
                monthlyMax: Yup.number(t('userDetailsPage.monthlyMaxFormat')).positive(
                  t('userDetailsPage.monthlyMaxFormat')
                ),
              })}
              onSubmit={(values) => {
                GlimraStore.updateUser(id, {
                  monthly_max: values.monthlyMax,
                  notes: values.notes,
                })
                  .then((data) => {
                    setUserData(data);

                    snackRef.current.updateSnack({
                      variant: 'success',
                      title: t('userDetailsPage.updateUserSuccess.title'),
                      message: t('userDetailsPage.updateUserSuccess.message'),
                    });
                  })
                  .catch((error) => {
                    snackRef.current.updateSnack({
                      variant: 'error',
                      title: t('userDetailsPage.updateUserError.title'),
                      message: extractErrorMessage(
                        error,
                        t('userDetailsPage.updateUserError.message')
                      ),
                    });
                  });
              }}
            >
              {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
                <CardContent>
                  <Box fontWeight="fontWeightBold">{t('userDetailsPage.updateUserCardTitle')}</Box>
                  <Grid container direction="column">
                    <SpacerVertical size="small" />
                    <TextField
                      id="notes"
                      label={t('userDetailsPage.noteLabel')}
                      value={values.notes}
                      onChange={handleChange}
                      error={touched.notes && errors.notes}
                      helperText={touched.notes && errors.notes}
                      onBlur={handleBlur}
                    />
                    <SpacerVertical size="small" />
                    <TextField
                      id="monthlyMax"
                      label={t('userDetailsPage.monthlyMaxLabel')}
                      type="number"
                      value={values.monthlyMax}
                      onChange={handleChange}
                      error={touched.monthlyMax && errors.monthlyMax}
                      helperText={touched.monthlyMax && errors.monthlyMax}
                      onBlur={handleBlur}
                    />
                    <SpacerVertical size="big" />
                  </Grid>
                  <Button color="default" variant="contained" onClick={handleSubmit}>
                    {t('userDetailsPage.updateUserButton')}
                  </Button>
                </CardContent>
              )}
            </Formik>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardContent>
              <Box fontWeight="fontWeightBold">{t('userDetailsPage.removeUserCardTitle')}</Box>
              <SpacerVertical size="small" />
              <Grid container direction="row" justify="center">
                <Button color="default" variant="contained" onClick={() => setDialogOpen(true)}>
                  {t('userDetailsPage.removeUserButton')}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Root>
      <Pageheader
        header={
          deletedUser
            ? t('userDetailsPage.headerRemovedUser')
            : t('userDetailsPage.headerActiveUser', {
                user: `${userData.first_name || ''} ${userData.last_name || ''}`,
              })
        }
        subtitle={t('userDetailsPage.subtitle')}
      />
      <GreyBox>
        <Container disableGutters>
          {loading ? <Loader /> : !deletedUser && <UserInformation />}
          <SpacerVertical size="big" />
          <Typography variant="h6" noWrap>
            <Box fontWeight="fontWeightBold">{t('userDetailsPage.washHistory')}</Box>
          </Typography>
          <MaterialTable
            title={t('userDetailsPage.washHistory')}
            columns={columns}
            components={{
              Container: ({ children }) => <div>{children}</div>,
              Toolbar: () => null,
            }}
            data={(query) => fetchUserWashData(query)}
            options={{
              debounceInterval: 350,
              pageSize: 10,
              search: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: theme.palette.grey.glimraLight,
                fontWeight: theme.typography.fontWeightBold,
              },
            }}
          />
          <RemoveUserDialog
            open={dialogOpen}
            mobile={userData?.mobile}
            onClose={() => setDialogOpen(false)}
            onRemove={() => history.push('/users')}
          />
        </Container>
      </GreyBox>
    </Root>
  );
};

export default withRouter(UserDetailsPage);
