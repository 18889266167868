import React from 'react';
import StartPage from '../pages/StartPage';
import ChooseSubscriptionPage from '../pages/ChooseSubscriptionPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import UpdateSubscriptionPage from '../pages/UpdateSubscriptionPage';
import PaymentMethodPage from '../pages/PaymentMethodPage';
import LevelPage from '../pages/LevelPage';
import UserDetailsPage from '../pages/UserDetailsPage';
import UserListPage from '../pages/UserListPage';
import InvoicePage from '../pages/InvoicePage';
import SettingsPage from '../pages/SettingsPage';
import LogoutPage from '../pages/LogoutPage';
import ConfirmResetPage from '../pages/ConfirmResetPage';
import ChooseLevelPage from '../pages/ChooseLevelPage';
import AcceptInvitationPage from '../pages/AcceptInvitationPage';
import ConfirmAdminPage from '../pages/ConfirmAdminPage';

import {
  Add,
  Assessment,
  CardMembership,
  CreditCard,
  DirectionsCar,
  Group,
  MeetingRoom,
  AttachMoney,
  Settings,
} from '@material-ui/icons';

const nonMenuPages = [
  {
    path: '/users/:id',
    component: UserDetailsPage,
  },
  {
    path: '/chooseLevel',
    component: ChooseLevelPage,
  },
  {
    path: '/subscriptions/:id',
    component: UpdateSubscriptionPage,
  },
];

export const drawerMenuRoutes = [
  {
    path: '/start',
    titleKey: 'drawer.startPage',
    icon: <DirectionsCar />,
    component: StartPage,
  },
  {
    path: '/chooseSubscription',
    titleKey: 'drawer.chooseSubscriptionPage',
    icon: <Add />,
    component: ChooseSubscriptionPage,
  },
  {
    path: '/subscriptions',
    titleKey: 'drawer.subscriptionsPage',
    icon: <CardMembership />,
    component: SubscriptionsPage,
  },
  {
    path: '/paymentMethod',
    titleKey: 'drawer.paymentMethodPage',
    icon: <CreditCard />,
    component: PaymentMethodPage,
  },
  {
    path: '/level',
    titleKey: 'drawer.levelPage',
    icon: <Assessment />,
    component: LevelPage,
  },
  {
    path: '/users',
    titleKey: 'drawer.usersPage',
    icon: <Group />,
    component: UserListPage,
  },
  {
    path: '/settings',
    titleKey: 'drawer.settingsPage',
    icon: <Settings />,
    component: SettingsPage,
  },
  {
    path: '/invoices',
    titleKey: 'drawer.invoicesPage',
    icon: <AttachMoney />,
    component: InvoicePage,
  },
];

export const authRoutes = [
  {
    path: '/logout',
    titleKey: 'drawer.logoutPage',
    icon: <MeetingRoom />,
    component: LogoutPage,
  },
];

export const openRoutes = [
  {
    path: '/confirmReset',
    component: ConfirmResetPage,
  },
  {
    path: '/acceptInvite',
    component: AcceptInvitationPage,
  },
  {
    path: '/confirmAdmin',
    component: ConfirmAdminPage,
  },
];

export const allRoutes = nonMenuPages
  .concat(drawerMenuRoutes)
  .concat(authRoutes)
  .concat(openRoutes)
  .concat([
    {
      path: '/',
      component: StartPage,
    },
  ]);
