import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GlimraStore from '../store/GlimraStore';
import SpacerVertical from './helpers/SpacerVertical';
import { snackRef } from './Snack';
import Button from './helpers/Button';
import { useTranslation } from 'react-i18next';

const ForgotPasswordDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('forgotPasswordDialog.title')}</DialogTitle>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required(t('forgotPasswordDialog.emailRequired'))
            .email(t('forgotPasswordDialog.emailFormat')),
        })}
        onSubmit={(values) => {
          // TODO: failure message is missing:
          GlimraStore.forgotPassword(values.email).then((data) => {
            snackRef.current.updateSnack({
              variant: 'success',
              title: t('forgotPasswordDialog.resetEmailSentSuccess.title'),
              message: t('forgotPasswordDialog.resetEmailSentSuccess.message'),
            });
            onClose();
          });
        }}
      >
        {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
          <DialogContent>
            <DialogContentText>{t('forgotPasswordDialog.designateEmail')}</DialogContentText>
            <TextField
              required
              id="email"
              variant="outlined"
              label={t('forgotPasswordDialog.emailLabel')}
              fullWidth
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
            />
            <SpacerVertical />
            <DialogActions>
              <Button onClick={onClose} color="default">
                {t('forgotPasswordDialog.abortButton')}
              </Button>
              <Button onClick={handleSubmit} color="default">
                {t('forgotPasswordDialog.sendButton')}
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
