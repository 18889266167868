import React, { useCallback, useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import 'typeface-roboto';
import { CssBaseline, withStyles } from '@material-ui/core';
import MainMenu from './components/MainMenu';
import Header from './components/Header';
import MainContent from './components/MainContent';
import { CompanyContext } from './store/CompanyContext';
import { LogInContext } from './store/LogInContext';
import FirstTimeContext from './store/FirstTimeContext';
import { Snack, snackRef } from './components/Snack';
import AuthStore from './store/AuthStore';
import GlimraStore from './store/GlimraStore';
import Palette from './components/Palette';
import LandingPage from './pages/LandingPage';
import CookieMessage from './components/CookieMessage';
import history from './store/HistoryStore';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import glimra_sv_SE from './translations/glimra_sv_SE';
import juhlapesu_sv_SE from './translations/juhlapesu_sv_SE';
import { openRoutes } from './navigation/routes';
import FBPixel from './trackers/FbPixel';
import { AlternativesContext } from './store/AlternativesContext';
import { AlternativeType } from './data/alternativeType';

const translation = process.env.REACT_APP_BRAND === 'juhlapesu' ? juhlapesu_sv_SE : glimra_sv_SE;

i18n.use(initReactI18next).init({
  resources: {
    sv_SE: {
      translation,
    },
  },
  lng: 'sv_SE',
  fallbackLng: 'sv_SE',

  interpolation: {
    escapeValue: false,
  },
});

const styles = () => ({
  root: {
    display: 'flex',
  },
});

const App = ({ classes }) => {
  const [loggedIn, setLoggedIn] = useState(AuthStore.getAuthStatus());
  const [companyData, setCompanyData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [alternatives, setAlternatives] = useState({});
  const { t } = useTranslation();

  const handleGetCompany = useCallback(() => {
    GlimraStore.getCompany()
      .then((data) => {
        setCompanyData(data);
        setLoggedIn(true);
      })
      .catch((error) => {
        const status = error?.response?.status;

        if (status === 404) {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('app.getCompanyError.title'),
            message: t('app.getCompanyError.message'),
          });
          AuthStore.logout();
          setLoggedIn(false);
        }
      });
  }, [t]);

  const handleGetAlternatives = useCallback(() => {
    if (Object.keys(alternatives).length === 0) {
      GlimraStore.fetchSubscriptionAlternatives().then((data) => {
        const sortFunc = (a, b) => {
          if (a.price < b.price) {
            return -1;
          }
          if (b.price > a.price) {
            return 1;
          }
          return 0;
        };

        const alternatives = data.results.reduce(
          (prev, curr) => {
            if (curr.type === AlternativeType.AVAILABILITY) {
              return { ...prev, availability: [...prev.availability, curr].sort(sortFunc) };
            }
            if (curr.type === AlternativeType.PROGRAM) {
              return { ...prev, program: [...prev.program, curr].sort(sortFunc) };
            }
            return { ...prev, binding: [...prev.binding, curr].sort(sortFunc) };
          },
          { availability: [], binding: [], program: [] }
        );

        setAlternatives(alternatives);
      });
    }
  }, [alternatives]);

  useEffect(() => {
    FBPixel.trackPortalPageView();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      handleGetCompany();
      handleGetAlternatives();
    } else {
      setLoaded(true);
    }
  }, [loggedIn, handleGetCompany, handleGetAlternatives]);

  useEffect(() => {
    if (companyData.id && Object.keys(alternatives).length !== 0) {
      setLoaded(true);
      if (firstTime) {
        history.push('/chooseLevel/');
      }
    }
  }, [companyData, alternatives, firstTime]);

  useEffect(() => {}, [alternatives]);

  const checkRouteValid = () => {
    if (loggedIn) {
      return true;
    }
    const currentPath = window.location.pathname;
    const allowedRoute = openRoutes.find((route) => currentPath.includes(route.path));

    return !!allowedRoute;
  };

  return (
    <Palette>
      <LogInContext.Provider value={{ loggedIn, setLoggedIn }}>
        <CompanyContext.Provider value={{ companyData, setCompanyData, handleGetCompany }}>
          <FirstTimeContext.Provider value={{ firstTime, setFirstTime }}>
            <AlternativesContext.Provider value={{ alternatives, setAlternatives }}>
              <Router history={history}>
                <div className={classes.root}>
                  <CssBaseline />

                  <Switch>
                    {!checkRouteValid() && <Route exact path="/" component={LandingPage} />}
                    <Route>
                      <Header toggleDrawer={() => setNavigationDrawerOpen(!navigationDrawerOpen)} />

                      {loggedIn && !firstTime && (
                        <MainMenu
                          drawerOpen={navigationDrawerOpen}
                          closeDrawer={() => setNavigationDrawerOpen(false)}
                        />
                      )}

                      <MainContent companyData={companyData} loaded={loaded} />
                    </Route>
                  </Switch>
                  <Snack />
                  <CookieMessage />
                </div>
              </Router>
            </AlternativesContext.Provider>
          </FirstTimeContext.Provider>
        </CompanyContext.Provider>
      </LogInContext.Provider>
    </Palette>
  );
};

export default withStyles(styles)(App);
