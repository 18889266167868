import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import ChooseSubscriptionStep1 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep1';
import ChooseSubscriptionStep2 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep2';
import ChooseSubscriptionStep3 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep3';
import { useTranslation } from 'react-i18next';
import { LogInContext } from '../store/LogInContext';
import history from '../store/HistoryStore';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import PaymentMethodBanner from '../components/PaymentMethodBanner';
import LoggedInContent from '../components/helpers/LoggedInContent';
import { withRouter } from 'react-router-dom';

const ChooseSubscriptionPage = () => {
  const { loggedIn } = useContext(LogInContext);
  const [step, setStep] = useState(1);
  const [chosenProgramId, setChosenProgramId] = useState();
  const [chosenAvailabilityOptionId, setChosenAvailabilityOptionId] = useState();
  const [chosenPaymentOptionId, setChosenPaymentOptionId] = useState();
  const [canAddSubscription, setCanAddSubscription] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    /*GlimraStore.fetchPaymentMethod()
      .then(() => setCanAddSubscription(true))
      .catch(() => setCanAddSubscription(false));*/
    setCanAddSubscription(true);
  }, []);

  useEffect(() => {
    /* Scroll up on step change */
    if (!loggedIn) {
      history.push('/start');
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [step, loggedIn]);

  const resetWizard = () => {
    setChosenProgramId();
    setChosenAvailabilityOptionId();
    setChosenPaymentOptionId();
    setStep(0);
  };

  const Step = () => {
    switch (step) {
      case 2:
        return (
          <ChooseSubscriptionStep2
            setStep={setStep}
            programId={chosenProgramId}
            availabiltyId={chosenAvailabilityOptionId}
            paymentIntervalId={chosenPaymentOptionId}
          />
        );
      case 3:
        return <ChooseSubscriptionStep3 resetWizard={resetWizard} />;
      case 1:
      default:
        return (
          <ChooseSubscriptionStep1
            chosenProgramId={chosenProgramId}
            chosenAvailabilityOptionId={chosenAvailabilityOptionId}
            chosenPaymentOptionId={chosenPaymentOptionId}
            canAddSubscription={canAddSubscription}
            setChosenProgramId={setChosenProgramId}
            setChosenAvailabilityOptionId={setChosenAvailabilityOptionId}
            setChosenPaymentOptionId={setChosenPaymentOptionId}
            setStep={setStep}
          />
        );
    }
  };

  return (
    <LoggedInRoot>
      <PageHeader
        header={t('chooseSubscriptionPage.header')}
        subtitle={t('chooseSubscriptionPage.subtitle')}
      />
      {!canAddSubscription && (
        <PaymentMethodBanner message={t('chooseSubscriptionPage.cannotAddSubscription')} />
      )}
      <LoggedInContent>
        <Box paddingTop="1rem">
          {step < 3 && (
            <Box paddingX="1rem">
              <Container maxWidth="sm" disableGutters>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                  {t('chooseSubscriptionPage.step', { step: step, maxStep: 2 })}
                </Typography>
              </Container>
            </Box>
          )}

          <Step />
        </Box>
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default withRouter(ChooseSubscriptionPage);
