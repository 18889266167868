import React, { useContext } from 'react';
import { withStyles, Box, Grid, Typography, styled } from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import FirstTimeContext from '../store/FirstTimeContext';
import { CompanyContext } from '../store/CompanyContext';
import SpacerVertical from '../components/helpers/SpacerVertical';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '../components/helpers/Button';
import { withRouter } from 'react-router-dom';
import { MEMBERSHIP_LEVELS } from '../store/definitions/constants';
import { useTranslation } from 'react-i18next';

const LevelCard = withStyles((theme) => ({
  root: ({ paid }) => ({
    backgroundColor: paid ? theme.palette.primary.main : '#C8C8C8',
    borderRadius: 20,
    color: theme.palette.common.white,
    position: 'relative',
    width: 255,
  }),
}))(Box);

const LevelCardContent = withStyles(() => ({
  root: {
    padding: '30px 20px',
    minWidth: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))(Box);

const LevelCardFrame = withStyles((theme) => ({
  root: ({ status }) => ({
    margin: 5,
    ...(status === 'current' && {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
      borderRadius: 20,
    }),
    ...(status === 'requested' && {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.tertiary.main,
      borderRadius: 20,
    }),
  }),
}))(Grid);

const LevelCardCaption = withStyles(() => ({
  root: {
    marginBottom: 10,
  },
}))(Typography);

const CardTitle = withStyles(() => ({
  root: {
    textTransform: 'uppercase',
  },
}))(Typography);

const ButtonWrapper = withStyles(() => ({
  root: {
    paddingTop: 20,
    minHeight: 36,
  },
}))(Box);

const Ribbon = styled('div')(() => ({
  width: 150,
  height: 150,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  right: 0,
}));

const RibbonText = styled('div')(({ theme }) => ({
  width: 120,
  height: 20,
  position: 'absolute',
  right: -30,
  top: 20,
  transform: 'rotate(45deg)',
  backgroundColor: theme.palette.tertiary.main,
  textTransform: 'uppercase',
  fontSize: '0.6rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LevelCards = ({ history }) => {
  const firstTimeContext = useContext(FirstTimeContext);
  const { companyData, handleGetCompany } = useContext(CompanyContext);
  const { t } = useTranslation();

  const chooseLevel = (levelId) => {
    if (firstTimeContext.firstTime) {
      GlimraStore.upgradeLevel(levelId)
        .then(() => {
          snackRef.current.updateSnack({
            variant: 'success',
            title: t('levelCards.upgradeLevelSuccess.title'),
            message: t('levelCards.upgradeLevelSuccess.message'),
          });

          firstTimeContext.setFirstTime(false);
          handleGetCompany();
          history.push('/users');
        })
        .catch((error) => {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('levelCards.upgradeLevelError.title'),
            message: extractErrorMessage(error, t('levelCards.upgradeLevelError.message')),
          });
        });
    } else {
      GlimraStore.requestLevel(levelId)
        .then(() => {
          snackRef.current.updateSnack({
            variant: 'success',
            title: t('levelCards.requestLevelSuccess.title'),
            message: t('levelCards.requestLevelSuccess.message'),
          });
          handleGetCompany();
        })
        .catch((error) => {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('levelCards.requestLevelError.title'),
            message: extractErrorMessage(error, t('levelCards.requestLevelError.message')),
          });
        });
    }
  };

  const levels = [
    {
      level: 1,
      title: MEMBERSHIP_LEVELS[1],
      percentage: companyData.level1_rebate,
      threshold: companyData.level1_threshold,
      popular: false,
      paid: true,
    },
    {
      level: 2,
      title: MEMBERSHIP_LEVELS[2],
      percentage: companyData.level2_rebate,
      threshold: companyData.level2_threshold,
      popular: true,
      paid: true,
    },
    {
      level: 3,
      title: MEMBERSHIP_LEVELS[3],
      percentage: companyData.level3_rebate,
      threshold: companyData.level3_threshold,
      popular: false,
      paid: true,
    },
    {
      level: 0,
      title: MEMBERSHIP_LEVELS[0],
      percentage: 0,
      threshold: 0,
      popular: false,
      paid: false,
    },
  ];

  const renderCard = (level, key) => {
    const frameStatus =
      (firstTimeContext.firstTime && 'none') ||
      (level.level === companyData.membership_level && 'current') ||
      (level.level === companyData.req_membership_level && 'requested') ||
      'none';

    return (
      <LevelCardFrame item key={key} status={frameStatus}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            {frameStatus === 'current' && (
              <LevelCardCaption variant="h6">{t('levelCards.chosenLevel')}</LevelCardCaption>
            )}
            {frameStatus === 'requested' && (
              <LevelCardCaption variant="h6">{t('levelCards.nextMonth')}</LevelCardCaption>
            )}
          </Grid>
          <Grid item>
            <LevelCard paid={level.paid}>
              {level.popular && (
                <Ribbon>
                  <RibbonText>{t('levelCards.popularChoice')}</RibbonText>
                </Ribbon>
              )}
              <LevelCardContent>
                {level.paid && <CardTitle variant="h4">{level.title}</CardTitle>}
                <Typography variant="h5">
                  {t('levelCards.rebatePercentage', { percentage: level.percentage })}
                </Typography>

                <SpacerVertical size="small" />

                <Box textAlign="center">{t('levelCards.from')}</Box>
                <Grid container direction="row" alignItems="baseline" justify="center">
                  <Grid item>
                    <Typography variant="h4">{level.threshold}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{t('levelCards.currency')}</Typography>
                  </Grid>
                </Grid>
                <Box textAlign="center">{t('levelCards.perMonth')}</Box>

                <SpacerVertical size="small" />

                {level.paid && (
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <CheckCircleIcon style={{ fontSize: '0.875rem' }} />
                    </Grid>
                    <Grid item>
                      {t('levelCards.rebateAllWashes', { percentage: level.percentage })}
                    </Grid>
                  </Grid>
                )}

                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <CheckCircleIcon style={{ fontSize: '0.875rem' }} />
                  </Grid>
                  <Grid item>{t('levelCards.invitedColleagues')}</Grid>
                </Grid>

                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <CheckCircleIcon style={{ fontSize: '0.875rem' }} />
                  </Grid>
                  <Grid item>{t('levelCards.invoice')}</Grid>
                </Grid>

                <ButtonWrapper>
                  <Button
                    onClick={() => chooseLevel(level.level)}
                    variant="contained"
                    color="default"
                    disableElevation
                  >
                    {t('levelCards.chooseButton')}
                  </Button>
                </ButtonWrapper>
              </LevelCardContent>
            </LevelCard>
          </Grid>
        </Grid>
      </LevelCardFrame>
    );
  };

  return (
    <Grid container direction="row" spacing={4} alignItems="flex-end">
      {levels.map((level, i) => renderCard(level, i))}
    </Grid>
  );
};

export default withRouter(LevelCards);
