import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorCode } from '../../helpers/apiUtils';
import * as Yup from 'yup';
import { vehicleRegistrationNumberValidation } from '../../helpers/formUtils';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';
// import { trackPayment } from '../../store/FBPixel';
// import getCurrency from '../../helpers/getCurrency';
import { AlternativesContext } from '../../store/AlternativesContext';
import { CompanyContext } from '../../store/CompanyContext';
import { VehicleIsTaxiAlert } from '../VehicleIsTaxiAlert';
// import { calculatePrice } from '../../helpers/alternativesUtils';

const INSUFFICIENT_FUNDS_CODE = 'insufficient_funds';
const NON_UNIQUE_CODE = 'unique';

const LoggedInCustomerForm = ({ programId, availabiltyId, paymentIntervalId, setStep }) => {
  const alternatives = useContext(AlternativesContext).alternatives;
  const company = useContext(CompanyContext);
  const { t } = useTranslation();

  const [queryStr, setQueryStr] = useState('');
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [queryList, setQueryList] = useState([]);

  const [vehicleIsTaxi, setVehicleIsTaxi] = useState(undefined);

  useEffect(() => {
    GlimraStore.listUsers(company.companyData['id'], 1, 10, queryStr, '+email').then(({ data }) => {
      setQueryList(
        data.results.map((item) => ({
          uuid: item.uuid,
          email: item.email,
          country: item.country,
        }))
      );
    });
  }, [company.companyData, queryStr]);

  useEffect(() => {
    var map = new Map();
    selectedMembers.forEach((item) => map.set(item.uuid, item));
    queryList.forEach((item) => map.set(item.uuid, item));
    var memberList = Array.from(map, ([key, value]) => value);
    memberList.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));
    setMemberOptions(memberList);
  }, [selectedMembers, queryList]);

  const formik = useFormik({
    initialValues: {
      vehicle_number: '',
      members: [],
      program: programId,
      availability: availabiltyId,
      binding: paymentIntervalId,
      autostart_enabled: false,
      autostart_program: null,
      autostart_upgrades: '',
    },
    onSubmit: (values) => {
      const data = {
        members: values.members,
        program_product: values.program,
        availability_product: values.availability,
        binding_time_product: values.binding,
        vehicle_number: values.vehicle_number,
        autostart_enabled: values.autostart_enabled,
        autostart_program: values.autostart_program,
        autostart_upgrades: values.autostart_upgrades,
      };

      // const cost = calculatePrice(
      //   alternatives,
      //   data.program_product,
      //   data.availability_product,
      //   data.binding_time_product
      // );

      if (vehicleIsTaxi === false || vehicleIsTaxi === undefined) {
        GlimraStore.createSubscription(data)
          .then((data) => {
            // if (data.country !== undefined) {
            //   trackPayment(cost, getCurrency(data.country));
            // }

            setStep(3);
          })
          .catch((error) => {
            const errorCode = extractErrorCode(error.response?.data);
            if (errorCode === INSUFFICIENT_FUNDS_CODE) {
              snackRef.current.updateSnack({
                variant: 'error',
                title: t('loggedInCustomerForm.createSubscriptionInsufficientFunds.title'),
                message: t('loggedInCustomerForm.createSubscriptionInsufficientFunds.message'),
              });
            } else if (errorCode === NON_UNIQUE_CODE) {
              snackRef.current.updateSnack({
                variant: 'error',
                title: t('loggedInCustomerForm.createSubscriptionUniqueError.title'),
                message: t('loggedInCustomerForm.createSubscriptionUniqueError.message'),
              });
            } else {
              snackRef.current.updateSnack({
                variant: 'error',
                title: t('loggedInCustomerForm.createSubscriptionError.title'),
                message: t('loggedInCustomerForm.createSubscriptionError.message'),
              });
            }
          });
      }
    },
    validationSchema: Yup.object().shape({
      vehicle_number: vehicleRegistrationNumberValidation(t),
    }),
  });

  const handleVehicleNumberBlur = async () => {
    const response = await GlimraStore.getVehicleIsTaxi(formik.values.vehicle_number);
    setVehicleIsTaxi(response.data['is_taxi']);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          required
          id="vehicle_number"
          label={t('existingCustomerForm.vehicleLabel')}
          value={formik.values.vehicle_number}
          onChange={(event) => {
            formik.setFieldValue('vehicle_number', event.target.value.toUpperCase());
          }}
          error={formik.touched.vehicle_number && !!formik.errors.vehicle_number}
          helperText={formik.touched.vehicle_number && formik.errors.vehicle_number}
          onBlur={handleVehicleNumberBlur}
          fullWidth
        />
        <VehicleIsTaxiAlert
          show={vehicleIsTaxi === true}
          vehicleNumber={formik.values.vehicle_number}
        />
      </Grid>
      <Grid item container direction="row" justifyContent="space-between">
        <Autocomplete
          id="members-box"
          multiple
          options={memberOptions}
          getOptionLabel={(item) => '' + item.email}
          getOptionSelected={(option, value) => option.uuid === value.uuid}
          fullWidth
          value={selectedMembers}
          onChange={(event, value, reason) => {
            setSelectedMembers(value);
            formik.setFieldValue(
              'members',
              value.map((item) => item.uuid),
              false
            );
          }}
          onInputChange={(event, value, reason) => {
            if (reason === 'input') {
              setQueryStr(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={t('existingCustomerForm.membersLabel')}
            />
          )}
        />
      </Grid>
      <Grid item>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="autostart_program">
            {t('existingCustomerForm.autostartProgramLabel')}
          </InputLabel>
          <Select
            id="autostart_program"
            value={
              !!alternatives?.program?.length && formik.values.autostart_program != null
                ? formik.values.autostart_program
                : ''
            }
            onChange={(e) => {
              formik.setFieldValue(
                'autostart_program',
                e.target.value !== '' ? e.target.value : null,
                false
              );
            }}
          >
            <MenuItem key="no-program" value="">
              <em>{t('existingCustomerForm.autostartNoProgramSelectedLabel')}</em>
            </MenuItem>
            {alternatives?.program?.map((option) => {
              return (
                <MenuItem key={option.id} value={option.data.program}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.autostart_enabled}
              name="autostart_enabled"
              onChange={(e) => {
                const program = alternatives.program?.find((option) => option.id === programId)
                  ?.data['program'];
                const autostart_enabled = e.target.checked && program !== undefined;
                formik.setFieldValue('autostart_enabled', autostart_enabled, false);
                if (!autostart_enabled) {
                  formik.setFieldValue('autostart_program', null, false);
                }
              }}
              onBlur={formik.handleBlur}
            />
          }
          margin="normal"
          label={t('existingCustomerForm.autostartEnabledLabel')}
          labelPlacement="start"
          value={true}
        />
        <DialogActions>
          <Button
            color="default"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={vehicleIsTaxi === undefined || vehicleIsTaxi === true}
          >
            {t('existingCustomerForm.buttonSend')}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default LoggedInCustomerForm;
