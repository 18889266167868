import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import StyledTextField from './helpers/StyledTextField';
import Button from './helpers/Button';
import Filler from './helpers/Filler';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ExistingAdminRegistrationForm = ({ loading, onUserRegistration, mobile }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      invited_code: '',
      invited_password: '',
      invited_confirmPassword: '',
      invited_mobile: mobile,
    },
    validationSchema: Yup.object().shape({
      invited_code: Yup.string().required(t('existingAdminRegistrationForm.invitedCodeRequired')),
      invited_password: Yup.string().required(t('existingAdminRegistrationForm.invitedPassword')),
      invited_mobile: Yup.string().required(
        t('existingAdminRegistrationForm.invitedMobileRequired')
      ),
      invited_confirmPassword: Yup.string()
        .required(t('existingAdminRegistrationForm.invitedPasswordRequired'))
        .oneOf(
          [Yup.ref('invited_password'), null],
          t('existingAdminRegistrationForm.invitedConfirmPasswordMatch')
        ),
    }),
    onSubmit: (values) => {
      onUserRegistration(values);
    },
  });

  return (
    <>
      <Grid item xs={12}>
        {t('existingAdminRegistrationForm.writeCode')}
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="invited_mobile"
          label={t('existingAdminRegistrationForm.invitedMobileLabel')}
          value={formik.values.invited_mobile}
          error={formik.errors.invited_mobile}
          touched={formik.touched.invited_mobile}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled
          required
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="invited_code"
          label={t('existingAdminRegistrationForm.invitedCodeLabel')}
          value={formik.values.invited_code}
          error={formik.errors.invited_code}
          touched={formik.touched.invited_code}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="invited_password"
          label={t('existingAdminRegistrationForm.invitedPasswordLabel')}
          type="password"
          value={formik.values.invited_password}
          error={formik.errors.invited_password}
          touched={formik.touched.invited_password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="invited_confirmPassword"
          label={t('existingAdminRegistrationForm.invitedConfirmPasswordLabel')}
          type="password"
          value={formik.values.invited_confirmPassword}
          error={formik.errors.invited_confirmPassword}
          touched={formik.touched.invited_confirmPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
        />
      </Grid>

      <Filler item />

      <Grid item xs={12}>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="default"
          fullWidth
          disabled={loading}
          disableElevation
        >
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            t('existingAdminRegistrationForm.completeFlowButton')
          )}
        </Button>
      </Grid>
    </>
  );
};

export default ExistingAdminRegistrationForm;
