import React, { useState } from 'react';
import {
  Card,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Modal,
  withStyles,
} from '@material-ui/core';
import SpacerVertical from '../helpers/SpacerVertical';
import Button from '../helpers/Button';
import ExistingCustomerForm from './ExistingCustomerForm';
import NewCustomerForm from './NewCustomerForm';
import { withRouter } from 'react-router-dom';
import AuthStore from '../../store/AuthStore';
import { useTranslation } from 'react-i18next';

const styles = () => ({
  centeredModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOutline: {
    outline: 0,
  },
  fullWidth: {
    width: '100%',
  },
});

const ChangeAdminModal = ({ open, onClose, classes, history }) => {
  const [newUser, setNewUser] = useState('existing');
  const [flowCompleted, setFlowCompleted] = useState(false);
  const { t } = useTranslation();

  const onFlowCompleted = () => {
    setFlowCompleted(true);
    AuthStore.logout();
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.centeredModal}>
      <Grid container item direction="column" xs={11} sm={8} className={classes.noOutline}>
        <Card className={classes.fullWidth}>
          <DialogTitle>{t('changeAdminModal.transferAdmin')}</DialogTitle>
          {flowCompleted ? (
            <DialogContent>
              <Grid container item direction="column" xs={12} alignItems="center">
                <Grid item>{t('changeAdminModal.accountTransfered')}</Grid>
                <SpacerVertical />
                <Grid item>
                  <Button color="default" onClick={() => history.push('/logout')}>
                    {t('changeAdminModal.redirect')}
                  </Button>
                </Grid>
              </Grid>
              <SpacerVertical />
            </DialogContent>
          ) : (
            <DialogContent>
              {t('changeAdminModal.doYouWantToTransfer')}
              <RadioGroup value={newUser} onChange={(event) => setNewUser(event.target.value)}>
                <Grid container item direction="row" xs={12}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value={'existing'}
                      control={<Radio />}
                      label={t('changeAdminModal.existingUser')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value={'new'}
                      control={<Radio />}
                      label={t('changeAdminModal.newUser')}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <SpacerVertical size="small" />
              {newUser === 'existing' ? (
                <ExistingCustomerForm onClose={onClose} onFlowCompleted={onFlowCompleted} />
              ) : (
                <NewCustomerForm onClose={onClose} onFlowCompleted={onFlowCompleted} />
              )}
            </DialogContent>
          )}
        </Card>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(withRouter(ChangeAdminModal));
