import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { Box, Card, CardContent, Container, Grid, TextField, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { extractErrorMessage } from '../helpers/apiUtils';
import GlimraStore from '../store/GlimraStore';
import { CompanyContext } from '../store/CompanyContext';
import Root from '../components/helpers/Root';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { snackRef } from '../components/Snack';
import ChangeAdminModal from '../components/ChangeAdminModal/ChangeAdminModal';
import GreyBox from '../components/helpers/GreyBox';
import PageHeader from '../components/PageHeader';
import Button from '../components/helpers/Button';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
  const [changeAdminModalOpen, setChangeAdminModalOpen] = useState(false);
  const companyData = useContext(CompanyContext).companyData;
  const { t } = useTranslation();

  return (
    <Root>
      <PageHeader header={t('settingsPage.header')} subtitle={t('settingsPage.subtitle')} />
      <GreyBox>
        <Container disableGutters>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={6} spacing={2} direction="column">
              <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant="h6" noWrap>
                      {t('settingsPage.invoiceAddress')}
                    </Typography>
                    <SpacerVertical />
                    <Formik
                      initialValues={(({
                        name,
                        orgnr,
                        invoice_email,
                        address,
                        zipnr,
                        city,
                        mobile,
                        reference,
                      }) => ({
                        name,
                        orgnr,
                        invoice_email,
                        address,
                        zipnr,
                        city,
                        mobile,
                        reference,
                      }))(companyData)}
                      validationSchema={Yup.object({
                        invoice_email: Yup.string().required(
                          t('settingsPage.invoiceEmailRequired')
                        ),
                        address: Yup.string().required(t('settingsPage.addressRequired')),
                        zipnr: Yup.string().required(t('settingsPage.zipnrRequired')),
                        city: Yup.string().required(t('settingsPage.cityRequired')),
                        mobile: Yup.string().required(t('settingsPage.mobileRequired')),
                        reference: Yup.string().required(t('settingsPage.referenceRequired')),
                      })}
                      onSubmit={(values) => {
                        GlimraStore.updateCompany(
                          (({ invoice_email, address, zipnr, city, mobile, reference }) => ({
                            invoice_email,
                            address,
                            zipnr,
                            city,
                            mobile,
                            reference,
                          }))(values)
                        )
                          .then(() => {
                            snackRef.current.updateSnack({
                              variant: 'success',
                              title: t('settingsPage.updateCompanySuccess.title'),
                              message: t('settingsPage.updateCompanySuccess.message'),
                            });
                          })
                          .catch((error) => {
                            snackRef.current.updateSnack({
                              variant: 'error',
                              title: t('settingsPage.updateCompanyError.title'),
                              message: extractErrorMessage(
                                error,
                                t('settingsPage.updateCompanyError.message')
                              ),
                            });
                          });
                      }}
                    >
                      {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
                        <>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <TextField
                                disabled
                                id="name"
                                label={t('settingsPage.companyNameLabel')}
                                value={values.name}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                disabled
                                id="orgnr"
                                label={t('settingsPage.orgnrLabel')}
                                value={values.orgnr}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="invoice_email"
                                label={t('settingsPage.invoiceEmailLabel')}
                                value={values.invoice_email}
                                onChange={handleChange}
                                error={touched.invoice_email && errors.invoice_email}
                                helperText={touched.invoice_email && errors.invoice_email}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="address"
                                label={t('settingsPage.addressLabel')}
                                value={values.address}
                                onChange={handleChange}
                                error={touched.address && errors.address}
                                helperText={touched.address && errors.address}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="zipnr"
                                label={t('settingsPage.zipnrLabel')}
                                value={values.zipnr}
                                onChange={handleChange}
                                error={touched.zipnr && errors.zipnr}
                                helperText={touched.zipnr && errors.zipnr}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="city"
                                label={t('settingsPage.citylabel')}
                                value={values.city}
                                onChange={handleChange}
                                error={touched.city && errors.city}
                                helperText={touched.city && errors.city}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="mobile"
                                label={t('settingsPage.mobileLabael')}
                                value={values.mobile}
                                onChange={handleChange}
                                error={touched.mobile && errors.mobile}
                                helperText={touched.mobile && errors.mobile}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                required
                                id="reference"
                                label={t('settingsPage.referenceLabal')}
                                value={values.reference}
                                onChange={handleChange}
                                error={touched.reference && errors.reference}
                                helperText={touched.reference && errors.reference}
                                onBlur={handleBlur}
                                fullWidth
                              />
                            </Grid>
                            <SpacerVertical />
                            <Grid item container justify={'flex-end'}>
                              <Button color="default" variant="contained" onClick={handleSubmit}>
                                {t('settingsPage.updateCompanyButton')}
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} spacing={2} direction="column">
              <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant="h6" noWrap>
                      {t('settingsPage.password')}
                    </Typography>
                    <SpacerVertical />
                    <Formik
                      initialValues={{
                        password: '',
                        repeat: '',
                        oldPassword: '',
                      }}
                      validationSchema={Yup.object({
                        oldPassword: Yup.string().required(t('settingsPage.oldPasswordRequired')),
                        password: Yup.string()
                          .required(t('settingsPage.passwordRequired'))
                          .min(8, t('settingsPage.passwordMin')),
                        repeat: Yup.string()
                          .required(t('settingsPage.repeatRequired'))
                          .oneOf([Yup.ref('password'), null], t('settingsPage.repeatMatch')),
                      })}
                      onSubmit={(data, formikProps) => {
                        GlimraStore.updatePassword(data)
                          .then((response) => {
                            snackRef.current.updateSnack({
                              variant: 'success',
                              title: t('settingsPage.updatePasswordSuccess.title'),
                              message: t('settingsPage.updatePasswordSuccess.message'),
                            });
                            formikProps.resetForm();
                          })
                          .catch((error) => {
                            snackRef.current.updateSnack({
                              variant: 'error',
                              title: t('settingsPage.updatePasswordError.title'),
                              message: extractErrorMessage(
                                error,
                                t('settingsPage.updatePasswordError.message')
                              ),
                            });
                          });
                      }}
                    >
                      {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <TextField
                              required
                              id="oldPassword"
                              label={t('settingsPage.oldPasswordLabel')}
                              type="password"
                              value={values.oldPassword}
                              onChange={handleChange}
                              error={touched.oldPassword && !!errors.oldPassword}
                              helperText={touched.oldPassword && errors.oldPassword}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              required
                              id="password"
                              label={t('settingsPage.passwordLabel')}
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                              error={touched.password && !!errors.password}
                              helperText={touched.password && errors.password}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              required
                              id="repeat"
                              type="password"
                              label={t('settingsPage.repeatLabel')}
                              value={values.repeat}
                              onChange={handleChange}
                              error={touched.repeat && !!errors.repeat}
                              helperText={touched.repeat && errors.repeat}
                              onBlur={handleBlur}
                              fullWidth
                            />
                          </Grid>
                          <SpacerVertical />
                          <Grid item container justify={'flex-end'}>
                            <Button color="default" variant="contained" onClick={handleSubmit}>
                              {t('settingsPage.updatePasswordButton')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant="h6" noWrap>
                      {t('settingsPage.adminEmail')}
                    </Typography>
                    <SpacerVertical />
                    <Grid item>
                      <Box fontWeight="fontWeightBold">{companyData.admin}</Box>
                    </Grid>
                    <SpacerVertical />
                    <Grid item container justify={'flex-end'}>
                      <Button
                        color="default"
                        variant="contained"
                        onClick={() => setChangeAdminModalOpen(true)}
                      >
                        {t('settingsPage.transferAdminButton')}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <ChangeAdminModal
            open={changeAdminModalOpen}
            onClose={() => setChangeAdminModalOpen(false)}
          />
        </Container>
      </GreyBox>
    </Root>
  );
};

export default SettingsPage;
