import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';

const glimraTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.glimraBlueDark,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 30,
        textTransform: 'none',
        fontWeight: 700,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: colors.glimraGreyMedium,
      },
    },
    MuiFab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 960px)': {
          minHeight: 106,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.glimraTealLight,
        },
      },
      gutters: {
        paddingLeft: 30,
      },
    },
  },
  typography: {
    fontFamily: ['BundaySansRegular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h4: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: colors.glimraBlueLight,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.glimraTealLight,
      contrastText: colors.white,
    },
    tertiary: {
      main: colors.glimraRedMedium,
    },
    defaultButton: {
      main: colors.glimraRedMedium,
      dark: colors.glimraRedDarker,
      light: colors.glimraRedLight,
      contrastText: '#fff',
    },
    error: {
      main: colors.glimraRedMedium,
      dark: colors.glimraRedDarker,
    },
    grey: {
      glimraLight: colors.glimraGreyLight,
      glimraMedium: colors.glimraGreyMedium,
      glimraDark: colors.glimraGreyDark,
    },
    footer: {
      start: colors.glimraFooterStart,
      end: colors.glimraFooterEnd,
      icon: colors.glimraIconTeal,
    },
    tonalOffset: 0.3,
    contrastThreshold: 3,
    background: {
      default: colors.white,
    },
  },
});

const juhlapesuTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.juhlapesuRedDark,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 30,
        textTransform: 'none',
        fontWeight: 700,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: colors.glimraGreyMedium,
      },
    },
    MuiFab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 960px)': {
          minHeight: 106,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.juhlapesuPink,
        },
      },
      gutters: {
        paddingLeft: 30,
      },
    },
  },
  typography: {
    fontFamily: ['Assistant', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h4: {
      fontWeight: 600,
      fontFamily: ['Anton', 'Assistant', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      main: colors.juhlapesuRed,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.juhlapesuPink,
      contrastText: colors.white,
    },
    tertiary: {
      main: colors.glimraRedMedium,
    },
    defaultButton: {
      main: colors.juhlapesuRedDark,
      dark: colors.juhlapesuRedDarker,
      light: colors.juhlapesuRed,
      contrastText: '#fff',
    },
    error: {
      main: colors.glimraRedMedium,
      dark: colors.glimraRedDarker,
    },
    grey: {
      glimraLight: colors.glimraGreyLight,
      glimraMedium: colors.glimraGreyMedium,
      glimraDark: colors.glimraGreyDark,
    },
    footer: {
      start: colors.juhlapesuRedDarker,
      end: colors.juhlapesuRedLight,
      icon: colors.juhlapesuRed,
    },
    tonalOffset: 0.3,
    contrastThreshold: 3,
    background: {
      default: colors.white,
    },
  },
});

const theme = process.env.REACT_APP_BRAND === 'juhlapesu' ? juhlapesuTheme : glimraTheme;

export default theme;
