import { withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';

const Button = withStyles((theme) => ({
  root: (props) =>
    props.color === 'default' && props.variant === 'contained'
      ? {
          ...(props.fullWidth && { width: '100%' }),
          color: theme.palette.defaultButton.contrastText,
          backgroundColor: theme.palette.defaultButton.main,
          '&:hover': {
            backgroundColor: theme.palette.defaultButton.dark,
            '@media (hover: none)': {
              backgroundColor: theme.palette.defaultButton.main,
            },
          },
        }
      : {},
}))(MuiButton);

export default Button;
