import { Redirect } from 'react-router-dom';
import React, { useContext } from 'react';
import { LogInContext } from '../../store/LogInContext';

const Root = ({ children }) => {
  const loginStatus = useContext(LogInContext);
  const loginRoute = '/';

  if (!loginStatus.loggedIn) {
    return <Redirect to={loginRoute} />;
  }

  return <>{children}</>;
};

export default Root;
