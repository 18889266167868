export const calculatePrice = (
  alternatives,
  chosenProgramId,
  chosenAvailabilityOptionId,
  chosenPaymentOptionId
) => {
  const programPrice = alternatives.program?.find((option) => option.id === chosenProgramId)?.price;
  const availabilityPrice = alternatives.availability?.find(
    (option) => option.id === chosenAvailabilityOptionId
  )?.price;
  const bindingTimePrice = alternatives.binding?.find(
    (option) => option.id === chosenPaymentOptionId
  )?.price;

  return (programPrice + availabilityPrice + bindingTimePrice).toFixed(0);
};
