const COUNTRIES = {
  SWEDEN: 'se',
  FINLAND: 'fi',
};

const getCountry = () => {
  const brand = process.env.REACT_APP_BRAND;
  switch (brand) {
    case 'juhlapesu':
      return COUNTRIES.FINLAND;
    case 'glimra':
    default:
      return COUNTRIES.SWEDEN;
  }
};

export default getCountry();
