import React from 'react';
import Root from '../components/helpers/Root';
import SpacerVertical from '../components/helpers/SpacerVertical';
import Pageheader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import { Typography, Container, Grid } from '@material-ui/core';
import LevelCards from '../components/LevelCards';
import { useTranslation } from 'react-i18next';
import Button from '../components/helpers/Button';
import { withRouter } from 'react-router-dom';

const ChooseLevelPage = ({ history }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Pageheader header={t('chooseLevelPage.header')} subtitle={t('chooseLevelPage.subtitle')} />
      <GreyBox>
        <Container disableGutters>
          <SpacerVertical />

          <Typography variant="h6">{t('chooseLevelPage.changeLevelMessage')}</Typography>

          <SpacerVertical />

          <LevelCards />

          <SpacerVertical size="big" />

          <Grid container justify="flex-end">
            <Grid item>
              <Button
                onClick={() => history.push('/users')}
                variant="contained"
                color="default"
                fullWidth
                disableElevation
              >
                {t('chooseUserTypeForm.sendButton')}
              </Button>
            </Grid>
          </Grid>

          <SpacerVertical />
        </Container>
      </GreyBox>
    </Root>
  );
};

export default withRouter(ChooseLevelPage);
