import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import RegistrationCard from './helpers/RegistrationCard';
import SpacerVertical from './helpers/SpacerVertical';
import NewAdminRegistrationForm from './NewAdminRegistrationForm';
import ExistingAdminRegistrationForm from './ExistingAdminRegistrationForm';
import ChooseUserTypeForm from './ChooseUserTypeForm';
import { useTranslation } from 'react-i18next';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const UserRegistrationForm = ({ loading, onRegister, onReset }) => {
  const { t } = useTranslation();
  const [userCheckResult, setUserCheckResult] = useState({});

  return (
    <Grid item xs={12} md={8}>
      <RegistrationCard container spacing={2} direction="column">
        <Grid item xs={12}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography variant="h6">{t('UserRegistrationForm.card2')}</Typography>
              <Typography variant="h5">{t('UserRegistrationForm.card2Title')}</Typography>
              <SpacerVertical size="small" />
            </Grid>
            <Grid item>
              <IconButton onClick={onReset}>
                <RotateLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {!userCheckResult.userChecked && <ChooseUserTypeForm onUserCheck={setUserCheckResult} />}

        {userCheckResult.userChecked && !userCheckResult.userExists && (
          <NewAdminRegistrationForm
            loading={loading}
            onUserRegistration={onRegister}
            mobile={userCheckResult.mobile}
          />
        )}
        {userCheckResult.userChecked && userCheckResult.userExists && (
          <ExistingAdminRegistrationForm
            loading={loading}
            onUserRegistration={onRegister}
            mobile={userCheckResult.mobile}
          />
        )}
      </RegistrationCard>
    </Grid>
  );
};

export default UserRegistrationForm;
