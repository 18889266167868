import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, MenuItem, InputLabel, Select, FormControl, Divider } from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import Root from '../components/helpers/Root';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { path, pathOr } from 'ramda';
import Loader from '../components/helpers/Loader';
import moment from 'moment';
import { CompanyContext } from '../store/CompanyContext';
import { formatMonthYear } from '../helpers/format';
import { MEMBERSHIP_LEVELS } from '../store/definitions/constants';
import PageHeader from '../components/PageHeader';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import GreyBox from '../components/helpers/GreyBox';
import { useTranslation } from 'react-i18next';
import Button from '../components/helpers/Button';
import { withRouter } from 'react-router-dom';

const StartPage = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const [dateAlternatives, setDateAlternatives] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({});
  const [monthlyStats, setMonthlyStats] = useState({});
  const company = useContext(CompanyContext);
  const { t } = useTranslation();

  useEffect(() => {
    const created = path(['companyData', 'created'], company);
    if (created) {
      setDateAlternatives(generateMonths(created));
    }
  }, [company]);

  useEffect(() => {
    if (dateAlternatives.length) {
      setSelectedMonth(dateAlternatives[dateAlternatives.length - 1]);
    }
  }, [dateAlternatives]);

  useEffect(() => {
    if (selectedMonth.title) {
      const params = {
        id: path(['companyData', 'id'], company),
        startDate: selectedMonth.startDate,
        endDate: selectedMonth.endDate,
      };

      GlimraStore.getPayments(params).then((data) => {
        setMonthlyStats(data);
        setLoaded(true);
      });
    }
  }, [company, selectedMonth]);

  const generateMonths = (startDate) => {
    const months = [];
    const currentDate = moment();
    let workingMonth = moment(startDate).startOf('month');

    while (workingMonth.isBefore(currentDate)) {
      months.push({
        title: formatMonthYear(workingMonth),
        startDate: workingMonth.format('YYYY-MM-DD'),
        endDate: moment(workingMonth).endOf('month').format('YYYY-MM-DD'),
      });

      workingMonth = workingMonth.add(1, 'M');
    }
    return months;
  };

  const membershipLevel =
    MEMBERSHIP_LEVELS[pathOr(0, ['companyData', 'membership_level'], company)];

  return (
    <Root>
      {loaded ? (
        <>
          <PageHeader header={t('startPage.header')} subtitle={t('startPage.subtitle')} />
          <GreyBox>
            <Grid item container xs={12} direction="column">
              <Grid item xs={12} container spacing={1} direction="row">
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={2} sm={2} md={1}>
                    <AccountCircleIcon fontSize="large" />
                  </Grid>
                  <Grid item xs={4}>
                    <Box fontWeight="fontWeightBold">{t('startPage.users')}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box fontWeight="fontWeightBold">{company.companyData?.nbr_users}</Box>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Grid container direction="row-reverse">
                      <Grid item>
                        <Button
                          color="default"
                          variant="contained"
                          disableElevation
                          onClick={() => history.push('/users')}
                        >
                          {t('startPage.addUserButton')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={2} sm={2} md={1}>
                    <SignalCellularAltIcon fontSize="large" />
                  </Grid>
                  <Grid item xs={4}>
                    <Box fontWeight="fontWeightBold">{t('startPage.level')}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box fontWeight="fontWeightBold">{membershipLevel}</Box>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Grid container direction="row-reverse">
                      <Grid item>
                        <Button
                          color="default"
                          variant="contained"
                          disableElevation
                          onClick={() => history.push('/level')}
                        >
                          {t('startPage.changeLevelButton')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid item container direction="column">
                    <Grid item>
                      <SpacerVertical size="big" />
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <InputLabel id="date-select-label">{t('startPage.month')}</InputLabel>
                        <Select
                          labelId="date-select-label"
                          id="date-select"
                          value={selectedMonth}
                          onChange={(e) => {
                            setSelectedMonth(e.target.value);
                          }}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {dateAlternatives.map((month, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={month}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {month.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <SpacerVertical />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={2} sm={2} md={1}>
                    <LocalCarWashIcon fontSize="large" />
                  </Grid>
                  <Grid item xs={4}>
                    <Box fontWeight="fontWeightBold">{t('startPage.washes')}</Box>
                  </Grid>
                  <Grid item>
                    <Box fontWeight="fontWeightBold">{monthlyStats.nbr_washes}</Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={2} sm={2} md={1}>
                    <LocalAtmIcon fontSize="large" />
                  </Grid>
                  <Grid item xs={4}>
                    <Box fontWeight="fontWeightBold">{t('startPage.cost')}</Box>
                  </Grid>
                  <Grid item>
                    <Box fontWeight="fontWeightBold">{monthlyStats.total_amount}</Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </GreyBox>
        </>
      ) : (
        <Loader />
      )}
    </Root>
  );
};

export default withRouter(StartPage);
