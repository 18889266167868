import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, TextField, DialogActions } from '@material-ui/core';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorMessage } from '../../helpers/apiUtils';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';

const ExistingCustomerForm = ({ onClose, onFlowCompleted }) => {
  const [numberInvited, setNumberInvited] = useState('');
  const { t } = useTranslation();

  return numberInvited ? (
    <Formik
      initialValues={{
        invited_mobile: '',
        invited_password: '',
        invited_code: '',
      }}
      validationSchema={Yup.object({
        invited_code: Yup.string().required(t('existingCustomerForm.invitedCodeRequired')),
        invited_password: Yup.string()
          .required(t('existingCustomerForm.invitedPasswordRequired'))
          .min(8, t('existingCustomerForm.invitedPasswordMin')),
        confirmPassword: Yup.string()
          .required(t('existingCustomerForm.confirmPasswordRequired'))
          .oneOf(
            [Yup.ref('invited_password'), null],
            t('existingCustomerForm.confirmPasswordMatch')
          ),
      })}
      onSubmit={(values) => {
        const data = {
          invited_mobile: numberInvited,
          invited_password: values.invited_password,
          invited_code: values.invited_code,
        };
        GlimraStore.updateCompany(data)
          .then(onFlowCompleted)
          .catch((error) => {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('existingCustomerForm.updateCompanyError.title'),
              message: extractErrorMessage(
                error,
                t('existingCustomerForm.updateCompanyError.message')
              ),
            });
          });
      }}
    >
      {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid item>{t('existingCustomerForm.writeSentCode')}</Grid>
            <Grid item>
              <TextField
                id="invited_code"
                label={t('existingCustomerForm.invitedCodeLabel')}
                type="number"
                required
                variant="filled"
                value={values.invited_code}
                error={touched.invited_code && !!errors.invited_code}
                helperText={touched.invited_code && errors.invited_code}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                required
                id="invited_password"
                label={t('existingCustomerForm.invitedPasswordLabel')}
                type="password"
                value={values.invited_password}
                onChange={handleChange}
                error={touched.invited_password && !!errors.invited_password}
                helperText={touched.invited_password && errors.invited_password}
                onBlur={handleBlur}
                fullWidth
                variant="filled"
              />
            </Grid>
            <Grid item>
              <TextField
                required
                id="confirmPassword"
                type="password"
                label={t('existingCustomerForm.confirmPasswordLabel')}
                value={values.confirmPassword}
                onChange={handleChange}
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                fullWidth
                variant="filled"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="default" variant="contained" onClick={handleSubmit}>
              {t('existingCustomerForm.completeSendButton')}
            </Button>
            <Button color="default" variant="contained" onClick={onClose}>
              {t('existingCustomerForm.completeAbortButton')}
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{ mobile: '' }}
      validationSchema={Yup.object({
        mobile: Yup.string().required(t('existingCustomerForm.mobileRequired')),
      })}
      onSubmit={(values) => {
        GlimraStore.requestVerificationCode(values.mobile)
          .then(() => setNumberInvited(values.mobile))
          .catch((error) => {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('existingCustomerForm.requestCodeError.title'),
              message: extractErrorMessage(
                error,
                t('existingCustomerForm.requestCodeError.message')
              ),
            });
          });
      }}
    >
      {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
        <>
          <TextField
            id="mobile"
            label={t('existingCustomerForm.mobileLabel')}
            type="phone"
            required
            variant="filled"
            value={values.mobile}
            error={touched.mobile && !!errors.mobile}
            helperText={touched.mobile && errors.mobile}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <DialogActions>
            <Button color="default" variant="contained" onClick={handleSubmit}>
              {t('existingCustomerForm.requestCodeSendButton')}
            </Button>
            <Button color="default" variant="contained" onClick={onClose}>
              {t('existingCustomerForm.requestCodeAbortButton')}
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default ExistingCustomerForm;
