import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { Card, CardContent, Container, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import GlimraStore from '../store/GlimraStore';
import { withRouter } from 'react-router-dom';
import Button from '../components/helpers/Button';
import GreyBox from '../components/helpers/GreyBox';
import PageHeader from '../components/PageHeader';
import { useTranslation } from 'react-i18next';

const ConfirmResetPage = ({ history }) => {
  const [resetCompleted, setResetCompleted] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const uid = params.get('uid');
  const { t } = useTranslation();

  if (!token || !uid) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <PageHeader header={t('confirmResetPage.header')} subtitle={t('confirmResetPage.subtitle')} />
      <GreyBox>
        <Container maxWidth="xs">
          <Card>
            <CardContent>
              <Formik
                initialValues={{
                  password: '',
                  repeat: '',
                }}
                validationSchema={Yup.object({
                  password: Yup.string()
                    .required(t('confirmResetPage.passwordRequired'))
                    .min(8, t('confirmResetPage.passwordMin')),
                  repeat: Yup.string()
                    .required(t('confirmResetPage.repeatRequired'))
                    .oneOf([Yup.ref('password'), null], t('confirmResetPage.repeatMatch')),
                })}
                onSubmit={(data, formikProps) => {
                  GlimraStore.resetPassword(data, token, uid)
                    .then((response) => {
                      snackRef.current.updateSnack({
                        variant: 'success',
                        title: t('confirmResetPage.resetPasswordSuccess.title'),
                        message: t('confirmResetPage.resetPasswordSuccess.message'),
                      });
                      formikProps.resetForm();
                      setResetCompleted(true);
                    })
                    .catch((error) => {
                      snackRef.current.updateSnack({
                        variant: 'error',
                        title: t('confirmResetPage.resetPasswordFail.title'),
                        message: extractErrorMessage(
                          error,
                          t('confirmResetPage.resetPasswordFail.message')
                        ),
                      });
                    });
                }}
              >
                {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                  <Grid container direction="column" spacing={2}>
                    {resetCompleted && (
                      <Grid item alignItems="center" justify="center" container direction="column">
                        {t('confirmResetPage.passwordResetMessage')}
                        <Button color="default" onClick={() => history.push('/')}>
                          {t('confirmResetPage.takeMeBackMessage')}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <TextField
                        required
                        id="password"
                        label={t('confirmResetPage.passwordLabel')}
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        id="repeat"
                        type="password"
                        label={t('confirmResetPage.repeatLabel')}
                        value={values.repeat}
                        onChange={handleChange}
                        error={touched.repeat && !!errors.repeat}
                        helperText={touched.repeat && errors.repeat}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </Grid>
                    <SpacerVertical />
                    <Grid item container justify={'flex-end'}>
                      <Button color="default" variant="contained" onClick={handleSubmit}>
                        {t('confirmResetPage.changePasswordButton')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </GreyBox>
    </>
  );
};

export default withRouter(ConfirmResetPage);
