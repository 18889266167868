import React, { useState, useEffect, useContext } from 'react';
import { withStyles, Container, Typography, Box, Grid } from '@material-ui/core';
import AuthStore from '../store/AuthStore';
import { LogInContext } from '../store/LogInContext';
import { snackRef } from '../components/Snack';
import ForgotPasswordDialog from '../components/ForgotPasswordDialog';
import Footer from '../components/Footer';
import RegisterForm from '../components/RegisterForm';
import LoginForm from '../components/LoginForm';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';
import IMAGES from '../styles/images';

const Title = withStyles((theme) => ({
  root: (props) => ({
    fontSize: '2.5rem',
    margin: `${theme.spacing(5)}px 0px`,
    color: theme.palette.common.white,
  }),
}))(Typography);

const styles = (theme) => ({
  header: {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    padding: '1rem 1rem 0.5rem 2rem',
    zIndex: 100,
    transition: theme.transitions.create('background-color'),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  elevateHeader: {
    backgroundColor: theme.palette.primary.main,
  },

  pageHeading: {
    fontWeight: 400,
    zIndex: 10,
  },

  container: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
    },
    paddingTop: '70px',
    paddingBottom: '70px',
  },

  background: {
    backgroundSize: 'cover',
    backgroundImage: `url(${IMAGES.landingPage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});

const LandingPage = ({ classes }) => {
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [elevateHeader, setElevateHeader] = useState(false);
  const { setLoggedIn } = useContext(LogInContext);
  const { t } = useTranslation();

  const toggleForgotPassword = () => {
    setForgotPasswordDialog((prevState) => !prevState);
  };

  const login = (email, password) =>
    AuthStore.login(email, password)
      .then(() => {
        setLoggedIn(true);
      })
      .catch((error) => {
        const status = error?.response?.status;

        if (status === 400) {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('landingPage.loginError.title'),
            message: t('landingPage.loginError.messageWrongCredentials'),
          });
        } else {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('landingPage.loginError.title'),
            message: t('landingPage.loginError.messageUknownError'),
          });
        }
      });

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setElevateHeader(true);
    } else {
      setElevateHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box flex={1} display="flex" flexDirection="column" className={classes.background}>
      <header className={`${classes.header} ${elevateHeader ? classes.elevateHeader : ''}`}>
        <Container>
          <a href={t('links.homepage')}>
            <Box component="img" src={IMAGES.logo} alt="Logo" maxWidth={175} />
          </a>
        </Container>
      </header>

      <Container component="main" maxWidth="lg" classes={{ root: classes.container }}>
        <Grid container alignItems="center" direction="column">
          <Grid item>
            <SpacerVertical size="big" />
          </Grid>
          <Grid item>
            <Title variant="h4">{t('landingPage.loginTitle')}</Title>
          </Grid>

          <LoginForm toggleForgotPassword={toggleForgotPassword} login={login} />
        </Grid>

        <Grid container justify="center">
          <Grid item>
            <Title variant="h4">{t('landingPage.registerTitle')}</Title>
          </Grid>
        </Grid>

        <RegisterForm login={login} />
      </Container>

      <Footer />

      <ForgotPasswordDialog open={forgotPasswordDialog} onClose={toggleForgotPassword} />
    </Box>
  );
};

export default withStyles(styles)(LandingPage);
