import React from 'react';
import { DialogActions, Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorMessage } from '../../helpers/apiUtils';
import * as Yup from 'yup';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';

const NewCustomerForm = ({ onClose, onFlowCompleted }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        mobile: '',
        password: '',
        passwordConfirmation: '',
        first_name: '',
        last_name: '',
      }}
      validationSchema={Yup.object({
        first_name: Yup.string().required(t('newCustomerForm.firstNameRequired')),
        last_name: Yup.string().required(t('newCustomerForm.lastNameRequired')),
        email: Yup.string()
          .required(t('newCustomerForm.emailRequired'))
          .email(t('newCustomerForm.emailFormat')),
        mobile: Yup.string().required(t('newCustomerForm.mobileRequired')),
        password: Yup.string()
          .required(t('newCustomerForm.passwordRequired'))
          .min(8, t('newCustomerForm.passwordMin')),
        passwordConfirmation: Yup.string()
          .required(t('newCustomerForm.passwordConfirmationRequired'))
          .oneOf([Yup.ref('password'), null], t('newCustomerForm.passwordConfirmationMatch')),
      })}
      onSubmit={(values) => {
        const data = {
          new_admin: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            mobile: values.mobile,
            password: values.password,
          },
        };
        GlimraStore.updateCompany(data)
          .then(onFlowCompleted)
          .catch((error) => {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('newCustomerForm.updateCompanyError.title'),
              message: extractErrorMessage(error, t('newCustomerForm.updateCompanyError.message')),
            });
          });
      }}
    >
      {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              required
              id="first_name"
              label={t('newCustomerForm.firstNameLabel')}
              value={values.first_name}
              onChange={handleChange}
              error={touched.first_name && errors.first_name}
              helperText={touched.first_name && errors.first_name}
              onBlur={handleBlur}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="last_name"
              label={t('newCustomerForm.lastNameLabel')}
              value={values.last_name}
              onChange={handleChange}
              error={touched.last_name && errors.last_name}
              helperText={touched.last_name && errors.last_name}
              onBlur={handleBlur}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="email"
              type="email"
              label={t('newCustomerForm.emailLabel')}
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              id="mobile"
              label={t('newCustomerForm.mobileLabel')}
              type="phone"
              required
              variant="filled"
              value={values.mobile}
              error={touched.mobile && !!errors.mobile}
              helperText={touched.mobile && errors.mobile}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="password"
              label={t('newCustomerForm.passwordLabel')}
              type="password"
              value={values.password}
              onChange={handleChange}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="passwordConfirmation"
              type="password"
              label={t('newCustomerForm.passwordConfirmationLabel')}
              value={values.passwordConfirmation}
              onChange={handleChange}
              error={touched.passwordConfirmation && !!errors.passwordConfirmation}
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              onBlur={handleBlur}
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item>
            <DialogActions>
              <Button color="default" variant="contained" onClick={handleSubmit}>
                {t('newCustomerForm.sendButton')}
              </Button>
              <Button color="default" variant="contained" onClick={onClose}>
                {t('newCustomerForm.abortButton')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default NewCustomerForm;
