import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import Button from './helpers/Button';
import { useTranslation } from 'react-i18next';

const RemoveUserDialog = ({ open, mobile, onClose, onRemove }) => {
  const { t } = useTranslation();

  const removeUser = () => {
    GlimraStore.removeUser(mobile).then(onRemove);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('removeUserDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('removeUserDialog.message', { mobile })}</DialogContentText>
        <DialogActions>
          <Button color="default" variant="contained" onClick={removeUser}>
            {t('removeUserDialog.removeUserButton')}
          </Button>
          <Button color="default" variant="contained" onClick={onClose}>
            {t('removeUserDialog.abortButton')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveUserDialog;
