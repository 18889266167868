import axios from 'axios';
import Config from './Config';
import ErrorHelper from '../helpers/ErrorHelper';
import country from '../helpers/country';
import { SubscriptionCancellationStatus } from '../data/subscriptionStatus';

class GlimraStore {
  constructor() {
    this.baseApiUrl = Config.baseApiUrl;
    const key = localStorage.getItem('token');
    this.noAuthInstance = axios.create({
      baseURL: Config.baseApiUrl(),
    });
    this.instance = axios.create({
      baseURL: Config.baseApiUrl(),
      headers: {
        common: {
          Authorization: `Token ${key}`,
        },
      },
    });
  }

  getCompanyInvitation = (code) => {
    return this.noAuthInstance.get(`/api/v1/companyinvite/${code}/`).then(({ data }) => data);
  };

  acceptInvitation = (code) => {
    return this.noAuthInstance.post(`/api/v1/companyinvite/${code}/accept`);
  };

  // This endpoint requires country being sent
  createCompany = (data) => {
    return this.noAuthInstance
      .post('/api/v1/company/', { ...data, membership_level: 0, country: country })
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  fetchSubscriptionAlternatives = () => {
    return this.noAuthInstance
      .get(`/api/v1/subscriptions/products/`, {
        params: {
          country: country,
        },
      })
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
      });
  };

  checkCompanyData = (data) => {
    return this.noAuthInstance.post('/api/v1/company/', {
      ...data,
      preview: true,
      country: country,
    });
  };

  getCompany = () => {
    return this.instance
      .get(`/api/v1/company/mine/`, {})
      .then(({ data }) => data)
      .catch((error) => ErrorHelper.handleApiError(error));
  };

  updateCompany = (data) => {
    return this.instance
      .put(`/api/v1/company/mine/`, data)
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  upgradeLevel = (membership_level) => {
    return this.instance
      .put('/api/v1/company/mine/', { membership_level })
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  requestLevel = (req_membership_level) => {
    return this.instance
      .put('/api/v1/company/mine/', { req_membership_level })
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  getInvoice = (id) => {
    return this.instance
      .get(`/api/v1/invoice/${id}/print`, { responseType: 'blob' })
      .then((response) => response)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  getUser = (id) => {
    return this.instance
      .get(`/api/v1/users/${id}/`, {})
      .then(({ data }) => data)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  updateUser = (id, data) => {
    return this.instance
      .put(`/api/v1/users/${id}`, data)
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  removeUser = (mobile) => {
    return this.instance
      .post(`/api/v1/company/mine/uninvite`, { mobile })
      .then(({ data }) => data)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  listUsers = async (id, page, pageSize, searchText, ordering) => {
    try {
      const response = await this.instance.get(`/api/v1/users/`, {
        params: {
          company: id,
          page,
          page_size: pageSize,
          search: searchText,
          ordering,
        },
      });
      return response;
    } catch (error) {
      return await ErrorHelper.handleApiError(error);
    }
  };

  listInvitedUsers = async (id, page, pageSize, searchText, ordering) => {
    try {
      const response = await this.instance.get(`/api/v1/companyinvite/`, {
        params: {
          company: id,
          page,
          page_size: pageSize,
          search: searchText,
          ordering,
        },
      });
      return response;
    } catch (error) {
      return await ErrorHelper.handleApiError(error);
    }
  };

  // This endpoint does not need to send country, the backend picks up country data from the company itself
  sendInvite = (data) => {
    return this.instance
      .post(`/api/v1/company/mine/invite`, data)
      .then((response) => response)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  getPayments = async ({ id, startDate, endDate }) => {
    try {
      const { data } = await this.instance.get(`/api/v1/paymentstats/`, {
        params: {
          company: id,
          created__gte: startDate,
          created__lte: endDate,
          company__isnull: false,
        },
      });
      return data;
    } catch (error) {
      return await ErrorHelper.handleApiError(error);
    }
  };

  listInvoices = (id, page, pageSize, ordering) => {
    return this.instance
      .get(`/api/v1/invoice/`, {
        params: {
          company: id,
          page: page,
          page_size: pageSize,
          ordering,
        },
      })
      .then((response) => response)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  listPayments = (id, page, pageSize, order) => {
    return this.instance
      .get(`/api/v1/payments/`, {
        params: {
          company: id,
          page: page,
          page_size: pageSize,
          gross_cost__gt: 0,
          company__isnull: false,
          order,
        },
      })
      .then((response) => response)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  listUserPayments = (companyId, userId, page, pageSize, ordering) => {
    return this.instance
      .get(`/api/v1/payments/`, {
        params: {
          company: companyId,
          user: userId,
          page: page,
          page_size: pageSize,
          gross_cost__gt: 0,
          company__isnull: false,
          ordering,
        },
      })
      .then((response) => response)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  updatePassword = ({ oldPassword, password, repeat }) => {
    return this.instance
      .post(`/api/v1/users/me/change_password`, {
        new_password1: password,
        new_password2: repeat,
        old_password: oldPassword,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  requestVerificationCode = (user) => {
    return this.noAuthInstance
      .post(`/api/v1/users/send_verification_code/`, { user, country: country })
      .then((response) => {
        return response;
      });
  };

  resetPassword = ({ password, repeat }, token, uid) => {
    return this.noAuthInstance
      .post(`/api/v1/users/confirm_reset/`, {
        new_password1: password,
        new_password2: repeat,
        token,
        uid,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  forgotPassword = (email) => {
    return this.noAuthInstance
      .post('/api/v1/users/password_reset_b2b/', { email })
      .then(({ data }) => data)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  fetchSubscription = (id) => {
    return this.instance
      .get(`/api/v1/subscriptions/companysubscription/${id}/`)
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  listSubscriptions = (page, pageSize, ordering) => {
    return this.instance
      .get(`/api/v1/subscriptions/companysubscription`, {
        params: {
          page: page,
          page_size: pageSize,
          ordering,
        },
      })
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
      });
  };

  fetchAllCompanySubscriptions = () => {
    return this.instance
      .get(`/api/v1/subscriptions/companysubscription/`, {})
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  createSubscription = (data) => {
    return this.instance
      .post(`/api/v1/subscriptions/companysubscription/`, data)
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  updateSubscriptionCancelled = (id, cancellationStatus) => {
    const getAction = () => {
      switch (cancellationStatus) {
        case SubscriptionCancellationStatus.CANCELLED:
          return 'reactivate';
        case SubscriptionCancellationStatus.QUEUED:
          return 'uncancel';
        case SubscriptionCancellationStatus.NOT_CANCELLED:
        default:
          return 'cancel';
      }
    };

    return this.instance
      .put(`/api/v1/subscriptions/companysubscription/${id}/${getAction()}/`, {})
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  fetchStripeClientSecret = () => {
    return this.instance
      .post(`api/v1/paymentmethods/setupintent/`, {}, {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  updateStripePaymentMethod = (paymentMethodId) => {
    return this.instance
      .post(
        `api/v1/paymentmethods/companypaymentmethod/`,
        { stripe_payment_method_id: paymentMethodId },
        {}
      )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  updateTrustpointPaymentMethod = (data) => {
    return this.instance
      .post(`api/v1/paymentmethods/companypaymentmethod/`, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  fetchPaymentMethod = () => {
    return this.instance
      .get(`api/v1/paymentmethods/companypaymentmethod/`, {})
      .then(({ data }) => data)
      .catch((error) => {
        // Returns 404 if no payment method exists and is a valid response, therefore do not use the ErrorHelper
        throw error;
      });
  };

  deletePaymentMethod = (id) => {
    return this.instance
      .delete(`api/v1/paymentmethods/companypaymentmethod/${id}/`, {})
      .then((response) => response)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  getVehicleIsTaxi = (vehicleNumber) => {
    return this.instance
      .get(`/public/vehicles/${vehicleNumber}/istaxi/`)
      .then((response) => response)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };
}

const singleton = new GlimraStore();
export default singleton;
