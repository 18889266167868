import React, { useContext } from 'react';
import { Box, Container, Grid, Typography, withStyles } from '@material-ui/core';
import SpacerVertical from '../helpers/SpacerVertical';
import { AlternativesContext } from '../../store/AlternativesContext';
import { calculatePrice } from '../../helpers/alternativesUtils';
import FabButton from '../helpers/FabButton';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  alternativeButton: {
    borderRadius: 6,
    minHeight: 86,
    width: '100%',
    '& > span': {
      flexDirection: 'column',
    },
  },
  alternativeButtonHeader: {
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  blueBox: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(4),
    color: theme.palette.common.white,
  },
  mainColumn: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  categoryHeader: {
    [theme.breakpoints.down('xs')]: {},
  },

  priceText: {
    fontWeight: '600',
    marginLeft: '0.25rem',
  },
});

const ChooseSubscriptionStep1 = ({
  chosenProgramId,
  chosenAvailabilityOptionId,
  chosenPaymentOptionId,
  setChosenProgramId,
  setChosenAvailabilityOptionId,
  setChosenPaymentOptionId,
  setStep,
  canAddSubscription,
  classes,
}) => {
  const alternatives = useContext(AlternativesContext).alternatives;
  const { t } = useTranslation();

  const Alternatives = ({ choices, chosenAlternativeId, choseAlternative }) => {
    const maxCost = Math.max(...choices.map((choice) => choice.price));

    return (
      <Grid item container direction="row" xs={12} justify="center" spacing={2}>
        {choices.map((choice) => {
          return (
            <Grid item xs={12} key={choice.id}>
              <Button
                classes={{ root: classes.alternativeButton }}
                color={choice.id === chosenAlternativeId ? 'primary' : 'secondary'}
                variant="contained"
                onClick={() => choseAlternative(choice.id)}
                key={choice.id}
              >
                <Typography variant="button" className={classes.alternativeButtonHeader}>
                  {choice.name}
                </Typography>
                {choice.type === 'program' && !!choice.price && (
                  <Box display="inherit" alignItems="baseline">
                    <Typography variant="caption">{t('chooseSubscriptionStep1.from')}</Typography>
                    <Typography className={classes.priceText}>
                      {choice.price} {t('chooseSubscriptionStep1.perMonth')}
                    </Typography>
                  </Box>
                )}
                {choice.type !== 'program' && maxCost - choice.price > 0 && (
                  <Typography component="span">
                    <Box display="flex">
                      <Box
                        bgcolor="defaultButton.main"
                        paddingX="1rem"
                        marginRight="0.25rem"
                        borderRadius={10}
                      >
                        {choice.price - maxCost} {t('chooseSubscriptionStep1.currency')}
                      </Box>
                      {t('chooseSubscriptionStep1.discountPerMonth')}
                    </Box>
                  </Typography>
                )}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getName = (typeAlternatives, id) => {
    return typeAlternatives.find((option) => option.id === id).name;
  };

  return (
    <>
      <Box paddingX="1rem">
        <Container disableGutters maxWidth="sm">
          <Typography variant="h6" noWrap className={classes.categoryHeader}>
            {t('chooseSubscriptionStep1.whatProgram')}
          </Typography>
          <SpacerVertical size="small" />
          <Alternatives
            choices={alternatives.program}
            chosenAlternativeId={chosenProgramId}
            choseAlternative={setChosenProgramId}
          />
          <SpacerVertical size="big" />
          <Typography variant="h6" noWrap className={classes.categoryHeader}>
            {t('chooseSubscriptionStep1.whatAvailability')}
          </Typography>
          <SpacerVertical size="small" />
          <Alternatives
            choices={alternatives.availability}
            chosenAlternativeId={chosenAvailabilityOptionId}
            choseAlternative={setChosenAvailabilityOptionId}
          />
          <SpacerVertical size="big" />
          <Typography variant="h6" noWrap className={classes.categoryHeader}>
            {t('chooseSubscriptionStep1.whatBinding')}
          </Typography>
          <SpacerVertical size="small" />
          <Alternatives
            choices={alternatives.binding}
            chosenAlternativeId={chosenPaymentOptionId}
            choseAlternative={setChosenPaymentOptionId}
          />
          <SpacerVertical size="big" />
        </Container>
      </Box>
      {!!chosenProgramId && !!chosenAvailabilityOptionId && !!chosenPaymentOptionId && (
        <>
          <Box className={classes.blueBox}>
            <Container disableGutters>
              <Grid container direction="column" alignItems="center">
                <Grid item container xs={10} sm={6} lg={4} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h5" noWrap>
                      <Box fontWeight="fontWeightBold">{t('chooseSubscriptionStep1.endPrice')}</Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5" noWrap>
                      <Box fontWeight="fontWeightBold">
                        {calculatePrice(
                          alternatives,
                          chosenProgramId,
                          chosenAvailabilityOptionId,
                          chosenPaymentOptionId
                        )}{' '}
                        {t('chooseSubscriptionStep1.perMonth')}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {t('chooseSubscriptionStep1.program')}
                  </Grid>
                  <Grid item xs={6}>
                    {getName(alternatives.program, chosenProgramId)}
                  </Grid>
                  <Grid item xs={6}>
                    {t('chooseSubscriptionStep1.availability')}
                  </Grid>
                  <Grid item xs={6}>
                    {getName(alternatives.availability, chosenAvailabilityOptionId)}
                  </Grid>
                  <Grid item xs={6}>
                    {t('chooseSubscriptionStep1.binding')}
                  </Grid>
                  <Grid item xs={6}>
                    {getName(alternatives.binding, chosenPaymentOptionId)}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box marginTop="1rem">
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <FabButton
                  onClick={() => setStep(2)}
                  disabled={!canAddSubscription}
                  variant="extended"
                  color="default"
                >
                  {t('chooseSubscriptionStep1.buttonNextStep')}
                </FabButton>
              </Grid>
              <Grid>
                <SpacerVertical size="big" />
              </Grid>
              <Grid item xs={8} lg={6}>
                <Box textAlign="center">{t('chooseSubscriptionStep1.termsAndConditions')}</Box>
              </Grid>
              <Grid>
                <SpacerVertical size="big" />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ChooseSubscriptionStep1);
