import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { AlternativesContext } from '../store/AlternativesContext';
import SpacerVertical from './helpers/SpacerVertical';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import GlimraStore from '../store/GlimraStore';
import { useTranslation } from 'react-i18next';
import { ColoredDialog } from './helpers/ColoredDialog';
import { calculatePrice } from '../helpers/alternativesUtils';

const ConfirmSubscriptionUpdateDialog = ({ open, onClose, id, newValues, oldValues }) => {
  const alternatives = useContext(AlternativesContext).alternatives;
  const { t } = useTranslation();

  const confirm = () => {
    const data = {
      chosen_program: newValues.program,
      chosen_availability: newValues.availability,
      chosen_binding_time: newValues.binding,
      vehicle_number: newValues.vehicle_number,
    };

    GlimraStore.updateSubscription(id, data)
      .then(() => {
        snackRef.current.updateSnack({
          variant: 'success',
          title: t('confirmSubscriptionUpdateDialog.updateSubscriptionSuccess.title'),
          message: t('confirmSubscriptionUpdateDialog.updateSubscriptionSuccess.message'),
        });
        onClose();
      })
      .catch((error) => {
        snackRef.current.updateSnack({
          variant: 'error',
          title: t('confirmSubscriptionUpdateDialog.updateSubscrtiptionError.title'),
          message: extractErrorMessage(
            error,
            t('confirmSubscriptionUpdateDialog.updateSubscrtiptionError.message')
          ),
        });
      });
  };

  const oldPrice = calculatePrice(
    alternatives,
    oldValues.program,
    oldValues.availability,
    oldValues.binding
  );

  const newPrice = calculatePrice(
    alternatives,
    newValues.program,
    newValues.availability,
    newValues.binding
  );

  return (
    <ColoredDialog
      open={open}
      onClose={onClose}
      title={t('confirmSubscriptionUpdateDialog.changesMade')}
      actions={[
        {
          text: t('confirmSubscriptionUpdateDialog.buttonConfirm'),
          onClick: confirm,
        },
      ]}
    >
      <Grid container>
        {newValues.program !== oldValues.program && (
          <>
            <Grid xs={6} sm={3} item>
              {t('confirmSubscriptionUpdateDialog.program')}
            </Grid>
            <Grid xs={6} sm={9} item>
              {alternatives.program?.find((program) => program.id === newValues.program)?.name}
            </Grid>
          </>
        )}
        {newValues.availability !== oldValues.availability && (
          <>
            <Grid xs={6} sm={3} item>
              {t('confirmSubscriptionUpdateDialog.availability')}
            </Grid>
            <Grid xs={6} sm={9} item>
              {
                alternatives.availability?.find(
                  (availability) => availability.id === newValues.availability
                )?.name
              }
            </Grid>
          </>
        )}
        {newValues.binding !== oldValues.binding && (
          <>
            <Grid xs={6} sm={3} item>
              {t('confirmSubscriptionUpdateDialog.binding')}
            </Grid>
            <Grid xs={6} sm={9} item>
              {alternatives.binding?.find((binding) => binding.id === newValues.binding)?.name}
            </Grid>
          </>
        )}
        <SpacerVertical />
        {newValues.vehicle_number !== oldValues.vehicle_number && (
          <Grid item>
            {t('confirmSubscriptionUpdateDialog.vehicleChange', {
              vehicle: newValues.vehicle_number,
            })}
          </Grid>
        )}
        {newPrice - oldPrice > 0 ? (
          <Grid item>
            {t('confirmSubscriptionUpdateDialog.priceChangeWithExtraCost', {
              extraCost: newPrice - oldPrice,
              monthlyCost: newPrice,
            })}
          </Grid>
        ) : (
          <Grid item>
            {t('confirmSubscriptionUpdateDialog.priceChange', {
              monthlyCost: newPrice,
            })}
          </Grid>
        )}
      </Grid>
      <SpacerVertical />
    </ColoredDialog>
  );
};

export default ConfirmSubscriptionUpdateDialog;
