import moment from 'moment';
import 'moment/locale/sv';

// TODO: Use the right locale here!

export const formatDate = (date) => {
  return date ? moment(date).format('YY-MM-DD') : 'N/A';
};

export const formatMonthYear = (date) => {
  return date ? moment(date).locale('sv').format('MMM - YYYY') : 'N/A';
};
