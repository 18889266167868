import GlimraStore from './GlimraStore';
import country from '../helpers/country';

class AuthStore {
  login = (email, password) => {
    const data = {
      email,
      password,
      country,
    };

    return GlimraStore.noAuthInstance
      .post('/api/v1/users/login/', data)
      .then(({ data }) => {
        const { key } = data;

        localStorage.setItem('token', key);
        GlimraStore.instance.defaults.headers.common['Authorization'] = `Token ${key}`;

        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  logout = () => {
    localStorage.removeItem('token');
  };

  getAuthStatus = () => {
    return !!localStorage.getItem('token');
  };

  getCookieConsentStatus = () => {
    return !!localStorage.getItem('cookieConsent');
  };

  acceptCookies = () => {
    localStorage.setItem('cookieConsent', true);
  };
}

const singleton = new AuthStore();
export default singleton;
