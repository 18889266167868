import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GlimraStore from '../store/GlimraStore';
import SpacerVertical from './helpers/SpacerVertical';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import Button from './helpers/Button';
import { useTranslation } from 'react-i18next';

const InviteUserDialog = ({ open, onClose, closeAndRefresh }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('inviteUserDialog.title')}</DialogTitle>
      <Formik
        initialValues={{ mobile: undefined }}
        validationSchema={Yup.object({
          mobile: Yup.string().required(t('inviteUserDialog.mobileRequired')),
        })}
        onSubmit={(values) => {
          GlimraStore.sendInvite(values)
            .then(closeAndRefresh)
            .catch((error) => {
              snackRef.current.updateSnack({
                variant: 'error',
                title: t('inviteUserDialog.sendInviteError.title'),
                message: extractErrorMessage(error, t('inviteUserDialog.sendInviteError.message')),
              });
            });
        }}
      >
        {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
          <DialogContent>
            <DialogContentText>{t('inviteUserDialog.instructions')}</DialogContentText>
            <TextField
              required
              id="mobile"
              label={t('inviteUserDialog.mobileLabel')}
              fullWidth
              value={values.mobile}
              onChange={handleChange}
              error={touched.mobile && errors.mobile}
              helperText={touched.mobile && errors.mobile}
              onBlur={handleBlur}
              variant="filled"
            />
            <SpacerVertical />
            <DialogActions>
              <Button onClick={handleSubmit} variant="contained" color="default">
                {t('inviteUserDialog.sendButton')}
              </Button>
              <Button onClick={onClose} variant="contained" color="default">
                {t('inviteUserDialog.abortButton')}
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default InviteUserDialog;
