import React, { useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import { snackRef } from './Snack';
import GlimraStore from '../store/GlimraStore';
import { extractErrorMessage } from '../helpers/apiUtils';
import FirstTimeContext from '../store/FirstTimeContext';
import { useTranslation } from 'react-i18next';
import FbPixel from '../trackers/FbPixel';
import CompanyRegistrationForm from './CompanyRegistrationForm';
import UserRegistrationForm from './UserRegistrationForm';

const RegisterForm = ({ login }) => {
  const setFirstTime = useContext(FirstTimeContext).setFirstTime;
  const [loading, setLoading] = useState(false);
  const [companyFilledIn, setCompanyFilledIn] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const { t } = useTranslation();

  const onCompanyRegistration = (companyData) => {
    setCompanyFilledIn(true);
    setCompanyData(companyData);
  };

  const onReset = () => {
    setCompanyFilledIn(false);
  };

  const onUserRegistration = (data) => {
    let userData;
    let userPassword;
    if (data.invited_code) {
      userData = data;
      userPassword = data.invited_password;
    } else {
      userData = {
        new_admin: data,
      };
      userPassword = data.password;
    }

    const mergedData = {
      ...userData,
      ...companyData,
    };

    setLoading(true);

    GlimraStore.createCompany(mergedData)
      .then((responseData) => {
        snackRef.current.updateSnack({
          variant: 'success',
          title: t('registerForm.createCompanySuccess.title'),
          message: t('registerForm.createCompanySuccess.message'),
        });
        const userEmail = responseData.admin;

        setFirstTime(true);

        FbPixel.trackCompanyRegistered();

        return login(userEmail, userPassword);
      })
      .catch((error) => {
        snackRef.current.updateSnack({
          variant: 'error',
          title: t('registerForm.createCompanyError.title'),
          message: extractErrorMessage(error, t('registerForm.createCompanyError.message')),
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container spacing={4} justify="center">
      {companyFilledIn ? (
        <UserRegistrationForm loading={loading} onRegister={onUserRegistration} onReset={onReset} />
      ) : (
        <CompanyRegistrationForm onRegister={onCompanyRegistration} initalValues={companyData} />
      )}
    </Grid>
  );
};

export default RegisterForm;
