import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function VehicleIsTaxiAlert({ show, vehicleNumber }) {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <Alert severity="warning" variant="filled">
          <div style={{ fontWeight: 'bold' }}>
            {t('vehicles.isTaxiWarning.title', {
              vehicleNumber: vehicleNumber,
            })}
          </div>
          {t('vehicles.isTaxiWarning.message')}
        </Alert>
      )}
    </>
  );
}
