import React from 'react';
import { Container, Typography } from '@material-ui/core';
import SpacerVertical from '../helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';
import FabButton from '../helpers/FabButton';
import history from '../../store/HistoryStore';

const ChooseSubscriptionStep3 = () => {
  const { t } = useTranslation();

  const toProfile = () => {
    history.push('/subscriptions');
  };

  return (
    <Container maxWidth="sm">
      <SpacerVertical />
      <Typography variant="h5" gutterBottom>
        {t('chooseSubscriptionStep3.thankYou')}
      </Typography>
      <SpacerVertical />
      <Typography variant="h6" gutterBottom>
        {t('chooseSubscriptionStep3.paragraph1')}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t('chooseSubscriptionStep3.paragraph2')}
      </Typography>
      <SpacerVertical />
      <FabButton color="default" variant="extended" onClick={toProfile}>
        {t('chooseSubscriptionStep3.buttonToProfile')}
      </FabButton>
      <SpacerVertical />
      <SpacerVertical />
    </Container>
  );
};

export default ChooseSubscriptionStep3;
