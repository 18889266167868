import { useContext, useEffect } from 'react';
import AuthStore from '../store/AuthStore';
import { LogInContext } from '../store/LogInContext';
import { CompanyContext } from '../store/CompanyContext';

const LogoutPage = ({ history }) => {
  const { loggedIn, setLoggedIn } = useContext(LogInContext);
  const { setCompanyData } = useContext(CompanyContext);

  useEffect(() => {
    AuthStore.logout();
    setCompanyData({});
    setLoggedIn(false);
  }, [setCompanyData, setLoggedIn]);

  useEffect(() => {
    /* Important to do this after loggedIn has been set to false */
    if (!loggedIn) {
      history.push('/');
    }
  }, [history, loggedIn]);

  return null;
};

export default LogoutPage;
