import React from 'react';
import GlimraStore from '../store/GlimraStore';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import history from '../store/HistoryStore';
import { useTranslation } from 'react-i18next';
import { ColoredDialog } from './helpers/ColoredDialog';
import { SubscriptionCancellationStatus } from '../data/subscriptionStatus';

const SubscriptionStatusDialog = ({ open, onClose, id, cancellationStatus }) => {
  const { t } = useTranslation();

  const getSuccessMessage = () => {
    switch (cancellationStatus) {
      case SubscriptionCancellationStatus.CANCELLED:
        return t('subscriptionStatusDialog.updateCancelledStatusSuccess.messageReactivated');
      case SubscriptionCancellationStatus.QUEUED:
        return t('subscriptionStatusDialog.updateCancelledStatusSuccess.messageUncancel');
      case SubscriptionCancellationStatus.NOT_CANCELLED:
      default:
        return t('subscriptionStatusDialog.updateCancelledStatusSuccess.messageCancelled');
    }
  };

  const getErrorMessage = () => {
    switch (cancellationStatus) {
      case SubscriptionCancellationStatus.CANCELLED:
        return t('subscriptionStatusDialog.updateCancelledStatusError.messageReactivated');
      case SubscriptionCancellationStatus.QUEUED:
        return t('subscriptionStatusDialog.updateCancelledStatusError.messageUncancel');
      case SubscriptionCancellationStatus.NOT_CANCELLED:
      default:
        return t('subscriptionStatusDialog.updateCancelledStatusError.messageCancelled');
    }
  };

  const updateSubscriptionCancelled = () => {
    GlimraStore.updateSubscriptionCancelled(id, cancellationStatus)
      .then(() => {
        snackRef.current.updateSnack({
          variant: 'success',
          title: t('subscriptionStatusDialog.updateCancelledStatusSuccess.title'),
          message: getSuccessMessage(),
        });
        history.push('/subscriptions');
      })
      .catch((error) => {
        snackRef.current.updateSnack({
          variant: 'error',
          title: t('subscriptionStatusDialog.updateCancelledStatusError.title'),
          message: extractErrorMessage(error, getErrorMessage()),
        });
      });
  };

  const getContent = () => {
    switch (cancellationStatus) {
      case SubscriptionCancellationStatus.CANCELLED:
        return {
          title: t('subscriptionStatusDialog.renewTitle'),
          message: t('subscriptionStatusDialog.renewMessage'),
          buttonText: t('subscriptionStatusDialog.buttonRenew'),
        };
      case SubscriptionCancellationStatus.QUEUED:
        return {
          title: t('subscriptionStatusDialog.uncancelTitle'),
          message: t('subscriptionStatusDialog.uncancelMessage'),
          buttonText: t('subscriptionStatusDialog.buttonUncancel'),
        };
      case SubscriptionCancellationStatus.NOT_CANCELLED:
      default:
        return {
          title: t('subscriptionStatusDialog.endSubscriptionTitle'),
          message: t('subscriptionStatusDialog.endSubscriptionMessage'),
          buttonText: t('subscriptionStatusDialog.endSubscriptionTitle'),
        };
    }
  };

  const content = getContent();

  return (
    <ColoredDialog
      open={open}
      onClose={onClose}
      title={content.title}
      actions={[
        {
          text: content.buttonText,
          onClick: updateSubscriptionCancelled,
        },
      ]}
      titleVariant="h5"
    >
      {content.message}
    </ColoredDialog>
  );
};

export default SubscriptionStatusDialog;
