import React from 'react';
import { withStyles, Toolbar, Typography, fade } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Switch, Route, withRouter } from 'react-router-dom';

import { allRoutes } from '../navigation/routes';
import { useTranslation } from 'react-i18next';

const objectIsEmpty = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

const styles = (theme) => ({
  content: (props) => ({
    flexGrow: 1,
    paddingTop: props.active === 'true' ? theme.spacing(3) : 0,
  }),
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.error.light, 0.5),
    padding: '0.5rem 1rem',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
});

const MainContent = ({ companyData, classes, loaded, location }) => {
  const { t } = useTranslation();

  return (
    <main
      className={classes.content}
      active={(objectIsEmpty(companyData) || companyData.is_active).toString()}
    >
      <Toolbar />
      {!objectIsEmpty(companyData) && !companyData.is_active && (
        <div className={classes.errorBox}>
          <ErrorIcon color="error" />
          <div>
            <Typography variant="h6">{t('mainContent.notVerifiedTitle')}</Typography>
            <Typography variant="body1">{t('mainContent.notVerifiedSubtitle')}</Typography>
          </div>
        </div>
      )}
      {(loaded || location.pathname === '/logout') && (
        <Switch>
          {allRoutes.map((route, key) => {
            return <Route path={route.path} component={route.component} key={key} />;
          })}
        </Switch>
      )}
    </main>
  );
};

export default withStyles(styles)(withRouter(MainContent));
