import React from 'react';
import { withStyles, Checkbox, CircularProgress, Grid } from '@material-ui/core';
import StyledTextField from './helpers/StyledTextField';
import Button from './helpers/Button';
import Filler from './helpers/Filler';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const StyledCheckbox = withStyles((theme) => ({
  root: (props) => ({
    padding: '0px 5px 0px 0px',
    ...(props.error && { color: theme.palette.error.main }),
  }),
}))(Checkbox);

const NewAdminRegistrationForm = ({ loading, onUserRegistration, mobile }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile,
      password: '',
      confirmPassword: '',
      confirmTerms: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required(t('newAdminRegistrationForm.firstNameRequired')),
      last_name: Yup.string().required(t('newAdminRegistrationForm.lastNameRequired')),
      email: Yup.string()
        .required(t('newAdminRegistrationForm.emailRequired'))
        .email(t('newAdminRegistrationForm.emailFormat')),
      mobile: Yup.string().required(t('newAdminRegistrationForm.newAdminMobileRequired')),
      password: Yup.string()
        .required(t('newAdminRegistrationForm.passwordRequired'))
        .min(8, t('newAdminRegistrationForm.passwordFormat')),
      confirmPassword: Yup.string()
        .required(t('newAdminRegistrationForm.confirmPasswordRequired'))
        .oneOf([Yup.ref('password'), null], t('newAdminRegistrationForm.confirmPasswordMatch')),
      confirmTerms: Yup.bool()
        .required(t('newAdminRegistrationForm.termsRequired'))
        .oneOf([true], t('newAdminRegistrationForm.termsRequired')),
    }),
    onSubmit: onUserRegistration,
  });

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              id="first_name"
              label={t('newAdminRegistrationForm.firstNameLabel')}
              value={formik.values.first_name}
              error={formik.errors.first_name}
              touched={formik.touched.first_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              id="last_name"
              label={t('newAdminRegistrationForm.lastNameLabel')}
              value={formik.values.last_name}
              error={formik.errors.last_name}
              touched={formik.touched.last_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              id="email"
              label={t('newAdminRegistrationForm.emailLabel')}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              id="mobile"
              label={t('newAdminRegistrationForm.mobileLabel')}
              value={formik.values.mobile}
              error={formik.errors.mobile}
              touched={formik.touched.mobile}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              disabled
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              id="password"
              label={t('newAdminRegistrationForm.passwordLabel')}
              type="password"
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              id="confirmPassword"
              label={t('newAdminRegistrationForm.confirmPasswordLabel')}
              type="password"
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <StyledCheckbox
          id="confirmTerms"
          error={formik.touched.confirmTerms && formik.errors.confirmTerms}
          checked={formik.values.confirmTerms}
          onChange={(event) => formik.setFieldValue('confirmTerms', event.target.checked)}
          onBlur={formik.handleBlur}
        />
        {t('newAdminRegistrationForm.termsText')}
      </Grid>

      <Filler item />

      <Grid item xs={12}>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="default"
          fullWidth
          disableElevation
        >
          {loading ? <CircularProgress size={25} /> : t('newAdminRegistrationForm.sendButton')}
        </Button>
      </Grid>
    </>
  );
};

export default NewAdminRegistrationForm;
