import React from 'react';
import { Box, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  greyBox: {
    backgroundColor: theme.palette.grey.glimraLight,
    padding: theme.spacing(4),
  },
});

const GreyBox = ({ classes, children }) => {
  return <Box className={classes.greyBox}>{children}</Box>;
};

export default withStyles(styles)(GreyBox);
