import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  pageHeader: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
});

const PageHeader = ({ header, subtitle, classes }) => {
  return (
    <Grid container direction="column" className={classes.pageHeader}>
      <Grid item>
        <Typography variant="h4">{header}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">{subtitle}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PageHeader);
