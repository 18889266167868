import ReactPixel from 'react-facebook-pixel';

class FbPixel {
  constructor() {
    this.active = !!process.env.REACT_APP_FACEBOOK_ID;

    if (this.active) {
      const fbOptions = {
        autoConfig: true,
        debug: process.env.NODE_ENV === 'development',
      };
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_ID, {}, fbOptions);
    }
  }

  trackCompanyRegistered = () => {
    if (this.active) {
      ReactPixel.trackCustom('CompanyRegistered', {});
    }
  };

  trackPortalPageView = () => {
    if (this.active) {
      ReactPixel.trackCustom('CompanyPortalPageView', {});
    }
  };
}

const singleton = new FbPixel();
export default singleton;
