import React from 'react';
import { Box, InputBase, withStyles, fade, InputLabel, FormControl } from '@material-ui/core';

const StyledInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey.glimraDark}`,
    fontSize: 14,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const ErrorText = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    display: 'inline',
  },
}))(Box);

const StyledInputLabel = withStyles((theme) => ({
  root: {
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  },
}))(InputLabel);

const StyledTextField = ({
  error,
  id,
  label,
  onBlur,
  onChange,
  required,
  value,
  touched,
  type,
  disabled,
}) => {
  const presentError = touched && !!error;

  return (
    <FormControl fullWidth>
      <StyledInputLabel shrink>
        {label} {required && '*'} {presentError && <ErrorText>/ {error}</ErrorText>}
      </StyledInputLabel>
      <StyledInput
        id={id}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default StyledTextField;
