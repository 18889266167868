import React, { useContext } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Hidden, IconButton, Grid, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import { LogInContext } from '../store/LogInContext';
import { CompanyContext } from '../store/CompanyContext';
import FirstTimeContext from '../store/FirstTimeContext';
import IMAGES from '../styles/images';

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    height: 32,
    width: 'auto',
    marginRight: 8,
    [theme.breakpoints.up('md')]: {
      height: 64,
    },
  },
});

const Header = ({ classes, toggleDrawer }) => {
  const loginStatus = useContext(LogInContext);
  const companyData = useContext(CompanyContext).companyData;
  const firstTime = useContext(FirstTimeContext).firstTime;

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        {loginStatus.loggedIn && !firstTime && (
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <img src={IMAGES.logo} alt="glimra logo" className={classes.logo} />
          </Grid>
          <Grid item>
            <Typography variant="h6" noWrap>
              {companyData.name}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
