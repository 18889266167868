import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import { Container } from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import Loader from '../components/helpers/Loader';
import { Redirect } from 'react-router-dom';

const ConfirmAdminPage = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const [invitationData, setInvitationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(false);

  useEffect(() => {
    GlimraStore.getCompanyInvitation(code)
      .then((data) => setInvitationData(data))
      .catch(() => {
        setResult(false);
        setLoading(false);
      });
  }, [code]);

  useEffect(() => {
    if (Object.keys(invitationData).length > 0) {
      GlimraStore.acceptInvitation(code)
        .then(() => setResult(true))
        .catch(() => setResult(false))
        .finally(() => setLoading(false));
    }
  }, [code, invitationData]);

  if (!code) {
    return <Redirect to={'/'} />;
  }

  const Message = () => {
    if (loading) {
      return <Loader />;
    }

    const company = invitationData.company || t('confirmAdmin.defaultCompanyName');

    return <>{result ? t('confirmAdmin.message', { company }) : t('confirmAdmin.errorMessage')}</>;
  };

  return (
    <>
      <PageHeader header={t('confirmAdmin.header')} subtitle={t('confirmAdmin.subtitle')} />
      <GreyBox>
        <Container maxWidth="xs">
          <Message />
        </Container>
      </GreyBox>
    </>
  );
};

export default ConfirmAdminPage;
