import React, { useContext } from 'react';
import { LogInContext } from '../../store/LogInContext';
import { Redirect } from 'react-router-dom';

const LoggedInRoot = ({ children }) => {
  const loggedIn = useContext(LogInContext).loggedIn;

  return loggedIn ? <>{children}</> : <Redirect to={'/'} />;
};

export default LoggedInRoot;
