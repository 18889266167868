import React, { useContext, useState } from 'react';
import Root from '../components/helpers/Root';
import MaterialTable from 'material-table';
import { path } from 'ramda';
import GlimraStore from '../store/GlimraStore';
import { CompanyContext } from '../store/CompanyContext';
import { formatDate, formatMonthYear } from '../helpers/format';
import { Box, Container, Modal, Grid, withWidth, isWidthDown } from '@material-ui/core';
import Loader from '../components/helpers/Loader';
import Pageheader from '../components/PageHeader';
import GreyBox from '../components/helpers/GreyBox';
import theme from '../styles/theme';
import { useTranslation } from 'react-i18next';

const InvoicePage = ({ width }) => {
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const company = useContext(CompanyContext);
  const { t } = useTranslation();

  const columns = [
    {
      title: t('invoicePage.columnPeriod'),
      field: 'month',
      render: ({ month }) => (
        <Box style={{ textTransform: 'capitalize' }}>{formatMonthYear(month)}</Box>
      ),
    },
    {
      title: t('invoicePage.columnWashes'),
      field: 'nbr_washes',
      hidden: isWidthDown('sm', width),
    },
    {
      title: t('invoicePage.columnAmount'),
      field: 'amount',
      render: ({ amount, currency }) => `${amount} ${currency}`,
    },
    {
      title: t('invoicePage.columnCreated'),
      field: 'created',
      render: ({ created }) => formatDate(created),
    },
  ];

  const fetchInvoiceData = ({ page, pageSize, orderBy, orderDirection }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listInvoices(
      path(['companyData', 'id'], company),
      page + 1,
      pageSize,
      order
    ).then(({ data }) => {
      return { data: data.results, page: page, totalCount: data.count };
    });
  };

  return (
    <Root>
      <Pageheader header={t('invoicePage.header')} subtitle={t('invoicePage.subtitle')} />
      <GreyBox>
        <Container disableGutters>
          <MaterialTable
            title={t('invoicePage.header')}
            components={{
              Container: ({ children }) => <div>{children}</div>,
              Toolbar: () => null,
            }}
            columns={columns}
            data={(query) => fetchInvoiceData(query)}
            options={{
              debounceInterval: 350,
              pageSize: 10,
              search: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: theme.palette.grey.glimraLight,
                fontWeight: theme.typography.fontWeightBold,
              },
            }}
            onRowClick={(e, { id }) => {
              setLoadingInvoice(true);
              GlimraStore.getInvoice(id)
                .then((response) => {
                  const file = new Blob([response.data], { type: 'application/pdf' });
                  const fileURL = window.URL.createObjectURL(file);
                  window.open(fileURL, 'Download');
                })
                .finally(() => {
                  setLoadingInvoice(false);
                });
            }}
          />
          <Modal open={loadingInvoice} onClose={() => setLoadingInvoice(false)}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ height: '100%' }}
              onClick={() => setLoadingInvoice(false)}
            >
              <Grid item>
                <Loader />
              </Grid>
            </Grid>
          </Modal>
        </Container>
      </GreyBox>
    </Root>
  );
};

export default withWidth()(InvoicePage);
