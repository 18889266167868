import React from 'react';
import StyledTextField from './helpers/StyledTextField';
import { useFormik } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from './helpers/Button';
import GlimraStore from '../store/GlimraStore';
import * as Yup from 'yup';

const ChooseUserTypeForm = ({ onUserCheck }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      mobile: '',
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required(t('chooseUserTypeForm.mobileRequired')),
    }),
    onSubmit: (values) => {
      const data = {
        userChecked: true,
        mobile: values.mobile,
      };
      GlimraStore.requestVerificationCode(values.mobile)
        .then(() => {
          onUserCheck({
            ...data,
            userExists: true,
          });
        })
        .catch((error) => {
          onUserCheck({
            ...data,
            userExists: false,
          });
        });
    },
  });

  return (
    <>
      <Grid item xs={12}>
        <StyledTextField
          id="mobile"
          label={t('chooseUserTypeForm.mobileLabel')}
          type="phone"
          value={formik.values.mobile}
          error={formik.errors.mobile}
          touched={formik.touched.mobile}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="default"
          fullWidth
          disableElevation
        >
          {t('chooseUserTypeForm.sendButton')}
        </Button>
      </Grid>
    </>
  );
};

export default ChooseUserTypeForm;
