import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import GlimraStore from '../store/GlimraStore';
import SpacerVertical from './helpers/SpacerVertical';
import Button from './helpers/Button';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { ColoredDialog } from './helpers/ColoredDialog';

const useStyles = makeStyles((theme) => ({
  code: {
    marginTop: '1rem',
    background: theme.palette.common.white,
    borderRadius: 10,
    padding: '0.25rem 2rem',
    fontWeight: '600',
  },
}));

const OneTimeCodeDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const [subscriptions, setSubscriptions] = useState([]);
  const [oneTimeData, setOneTimeData] = useState({});
  const [activeVehicleNbr, setActiveVehicleNbr] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    GlimraStore.fetchAllCompanySubscriptions()
      .then((data) => {
        setSubscriptions(data.results);
      })
      .catch((error) => {
        snackRef.current.updateSnack({
          variant: 'error',
          title: t('oneTimeCodeDialog.fetchAllUserSubscriptionsError.title'),
          message: extractErrorMessage(
            error,
            t('oneTimeCodeDialog.fetchAllUserSubscriptionsError.message')
          ),
        });
      });
  }, [t]);

  const resetAndClose = () => {
    setOneTimeData({});
    onClose();
  };

  const getOneTimeCode = (id, vehicle_number) => {
    setActiveVehicleNbr(vehicle_number);
    GlimraStore.fetchOneTimeCode(id)
      .then((data) => setOneTimeData(data))
      .catch((error) => {
        snackRef.current.updateSnack({
          variant: 'error',
          title: t('oneTimeCodeDialog.fetchOneTimeCodeError.title'),
          message: extractErrorMessage(error, t('oneTimeCodeDialog.fetchOneTimeCodeError.message')),
        });
      });
  };

  const activeOneTimeCode = !isEmpty(oneTimeData);

  return (
    <ColoredDialog
      open={open}
      onClose={resetAndClose}
      title={
        activeOneTimeCode
          ? t('oneTimeCodeDialog.codeCreated', { activeVehicleNbr })
          : t('oneTimeCodeDialog.title')
      }
      titleAlign="center"
    >
      {activeOneTimeCode ? (
        <>
          <Typography align="center" variant="h5" classes={{ root: classes.titleText }}>
            {t('oneTimeCodeDialog.codeIs')}
          </Typography>
          <div>
            <Typography
              variant="h5"
              align="center"
              noWrap
              classes={{ root: classes.code }}
              color="primary"
            >
              {oneTimeData.onetime_code}
            </Typography>
          </div>
        </>
      ) : (
        <>
          {t('oneTimeCodeDialog.sensorProblem')}
          <SpacerVertical />
          <Grid container spacing={1}>
            {subscriptions.map((subscription) => {
              return (
                <Grid item key={subscription.id}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => getOneTimeCode(subscription.id, subscription.vehicle_number)}
                  >
                    {subscription.vehicle_number}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      <SpacerVertical size="small" />
    </ColoredDialog>
  );
};

export default OneTimeCodeDialog;
