import { withStyles, Grid } from '@material-ui/core';

const RegistrationCard = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.glimraLight,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minHeight: '670px',
      padding: theme.spacing(3),
    },
  },
}))(Grid);

export default RegistrationCard;
