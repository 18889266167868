import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, MenuItem, withStyles } from '@material-ui/core';
import Button from '../components/helpers/Button';
import { AlternativesContext } from '../store/AlternativesContext';
import GlimraStore from '../store/GlimraStore';
import Loader from '../components/helpers/Loader';
import { calculatePrice } from '../helpers/alternativesUtils';
import { Formik } from 'formik';
import SpacerVertical from '../components/helpers/SpacerVertical';
import * as Yup from 'yup';
import { snackRef } from '../components/Snack';
import ConfirmSubscriptionUpdateDialog from '../components/ConfirmSubscriptionUpdateDialog';
import SubscriptionStatusDialog from '../components/SubscriptionStatusDialog';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import { vehicleRegistrationNumberValidation } from '../helpers/formUtils';
import PageHeader from '../components/PageHeader';
import { useTranslation } from 'react-i18next';
import LoggedInContent from '../components/helpers/LoggedInContent';
import WhiteTextField from '../components/helpers/WhiteTextField';
import WhiteSelectField from '../components/helpers/WhiteSelectField';
import { SubscriptionCancellationStatus, SubscriptionStatus } from '../data/subscriptionStatus';
import moment from 'moment';

const styles = (theme) => ({
  cancelledMessage: {
    fontWeight: theme.typography.fontWeightBold,
    padding: 20,
  },
});

const UpdateSubscriptionPage = ({
  match: {
    params: { id },
  },
  classes,
}) => {
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [confirmUpdateDialogOpen, setConfirmUpdateDialogOpen] = useState(false);
  const [subscriptionStatusDialogOpen, setSubscriptionStatusDialogOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const alternatives = useContext(AlternativesContext).alternatives;
  const { t } = useTranslation();

  useEffect(() => {
    GlimraStore.fetchSubscription(id)
      .then((data) => {
        setSubscriptionData(data);
        setLoading(false);
      })
      .catch(() => {});
  }, [id]);

  const statusMessage = () => {
    const status = subscriptionData?.status;
    if (status === SubscriptionStatus.CANCELED) {
      // Cancelled
      return t('updateSubscriptionPage.status.cancelled');
    }
    if (subscriptionData?.cancel_date) {
      return t('updateSubscriptionPage.status.queuedCancellation');
    }
    if (status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.TRIALING) {
      return t('updateSubscriptionPage.status.active');
    }
    return t('updateSubscriptionPage.status.inactive');
  };

  const getCancellationStatus = () => {
    const status = subscriptionData?.status;
    if (status === SubscriptionStatus.CANCELED) {
      return SubscriptionCancellationStatus.CANCELLED;
    }
    if (subscriptionData?.cancel_date) {
      return SubscriptionCancellationStatus.QUEUED;
    }
    return SubscriptionCancellationStatus.NOT_CANCELLED;
  };

  const cancellationStatus = getCancellationStatus();

  const getStatusButtonText = () => {
    switch (cancellationStatus) {
      case SubscriptionCancellationStatus.CANCELLED:
        return t('updateSubscriptionPage.buttonReactivateSubscription');
      case SubscriptionCancellationStatus.QUEUED:
        return t('updateSubscriptionPage.buttonDiscontinueSubscriptionCancellation');
      case SubscriptionCancellationStatus.NOT_CANCELLED:
      default:
        return t('updateSubscriptionPage.buttonCancelSubscription');
    }
  };

  const oldValues = {
    program: subscriptionData?.program?.id,
    availability: subscriptionData?.availability?.id,
    binding: subscriptionData?.binding_time?.id,
    vehicle_number: subscriptionData?.vehicle_number,
  };

  const cancelledMessage = () => {
    if (cancellationStatus === SubscriptionCancellationStatus.CANCELLED) {
      return (
        <Grid item container justify="center" className={classes.cancelledMessage}>
          {t('updateSubscriptionPage.subscriptionCancelled')}
        </Grid>
      );
    }
    if (cancellationStatus === SubscriptionCancellationStatus.QUEUED) {
      const nextPaymentDate = subscriptionData?.next_payment_date;
      const cancelDate = subscriptionData?.cancel_date
        ? moment(nextPaymentDate).format('YYYY-MM-DD')
        : moment(subscriptionData?.cancel_date).format('YYYY-MM-DD');

      return (
        <Grid item container justify="center" className={classes.cancelledMessage}>
          {t('updateSubscriptionPage.plannedCancellation', { cancelDate })}
        </Grid>
      );
    }
    return null;
  };

  return (
    <LoggedInRoot>
      <PageHeader
        header={t('updateSubscriptionPage.header')}
        subtitle={t('updateSubscriptionPage.subtitle')}
      />
      <LoggedInContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={oldValues}
              onSubmit={(newValues) => {
                // Remove unchanged values to pass change requirements
                const diff = {
                  ...(newValues.program !== oldValues.program && {
                    program: newValues.program,
                  }),
                  ...(newValues.availability !== oldValues.availability && {
                    availability: newValues.availability,
                  }),
                  ...(newValues.binding !== oldValues.binding && {
                    binding: newValues.binding,
                  }),
                  ...(newValues.vehicle_number !== oldValues.vehicle_number && {
                    vehicle_number: newValues.vehicle_number,
                  }),
                };

                if (Object.keys(diff).length === 0) {
                  snackRef.current.updateSnack({
                    variant: 'error',
                    title: t('updateSubscriptionPage.updateSubscriptionNoChange.title'),
                    message: t('updateSubscriptionPage.updateSubscriptionNoChange.message'),
                  });
                } else {
                  setPreviewData(newValues);
                  setConfirmUpdateDialogOpen(true);
                }
              }}
              validationSchema={Yup.object().shape({
                vehicle_number: vehicleRegistrationNumberValidation(t),
                program: Yup.string().required(t('formUtils.vehicleRequired')),
                availability: Yup.string().required(t('formUtils.vehicleRequired')),
                binding: Yup.string().required(t('formUtils.vehicleRequired')),
              })}
            >
              {({ values, touched, errors, setFieldValue, handleBlur, handleSubmit }) => (
                <Grid container direction="column" item xs={12} sm={6}>
                  <Grid item container direction="row" justify="space-between">
                    <WhiteTextField
                      label={t('updateSubscriptionPage.statusLabel')}
                      disabled
                      value={statusMessage()}
                      fullWidth
                    />
                  </Grid>
                  {!!subscriptionData?.next_payment_date && (
                    <Grid item container direction="row" justify="space-between">
                      <WhiteTextField
                        label={t('updateSubscriptionPage.nextPaymentLabel')}
                        disabled
                        value={moment(subscriptionData?.next_payment_date).format('YYYY-MM-DD')}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <WhiteTextField
                      id="vehicle_number"
                      label={t('updateSubscriptionPage.vehicleLabel')}
                      disabled
                      required
                      value={values.vehicle_number}
                      error={touched.vehicle_number && !!errors.vehicle_number}
                      helperText={touched.vehicle_number && errors.vehicle_number}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue('vehicle_number', event.target.value.toUpperCase());
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <WhiteSelectField
                      fullWidth
                      label={t('updateSubscriptionPage.subscription')}
                      id="program"
                      disabled
                      value={values.program}
                      onChange={(e) => {
                        setFieldValue('program', e.target.value, false);
                      }}
                    >
                      {alternatives.program.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </WhiteSelectField>
                  </Grid>
                  <Grid item>
                    <WhiteSelectField
                      fullWidth
                      id="availability"
                      label={t('updateSubscriptionPage.availablilty')}
                      disabled
                      value={values.availability}
                      onChange={(e) => {
                        setFieldValue('availability', e.target.value, false);
                      }}
                    >
                      {alternatives.availability.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </WhiteSelectField>
                  </Grid>
                  <Grid item>
                    <WhiteSelectField
                      fullWidth
                      label={t('updateSubscriptionPage.binding')}
                      id="binding"
                      disabled
                      value={values.binding}
                      onChange={(e) => {
                        setFieldValue('binding', e.target.value, false);
                      }}
                    >
                      {alternatives.binding.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </WhiteSelectField>
                  </Grid>
                  <Grid item container direction="row" justify="space-between">
                    <WhiteTextField
                      label={t('updateSubscriptionPage.bindingEndLabel')}
                      disabled
                      value={moment(subscriptionData.binding_end).format('YYYY-MM-DD')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item container direction="row" justify="space-between">
                    <WhiteTextField
                      label={t('updateSubscriptionPage.price')}
                      disabled
                      value={
                        calculatePrice(
                          alternatives,
                          values.program,
                          values.availability,
                          values.binding
                        ) + ' / mån'
                      }
                      fullWidth
                    />
                  </Grid>
                  {cancelledMessage()}
                  <Grid item>
                    <SpacerVertical />
                  </Grid>
                  <Grid item container direction="row" justify="space-between">
                    <Button
                      onClick={() => setSubscriptionStatusDialogOpen(true)}
                      variant="contained"
                      color="default"
                    >
                      {getStatusButtonText()}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </>
        )}
        <ConfirmSubscriptionUpdateDialog
          open={confirmUpdateDialogOpen}
          newValues={previewData}
          oldValues={oldValues}
          onClose={() => setConfirmUpdateDialogOpen(false)}
          id={id}
        />
        <SubscriptionStatusDialog
          open={subscriptionStatusDialogOpen}
          onClose={() => setSubscriptionStatusDialogOpen(false)}
          id={id}
          cancellationStatus={cancellationStatus}
        />
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default withStyles(styles)(withRouter(UpdateSubscriptionPage));
