import glimraLogo from '../assets/img/logo-glimra.png';
import glimraBadgeGooglePlay from '../assets/img/google-play-badge-glimra.png';
import glimraBadgeAppleAppStore from '../assets/img/appstore-badge-glimra.svg';
import juhlapesuLogo from '../assets/img/logo-juhlapesu.png';
import juhlapesuBadgeGooglePlay from '../assets/img/google-play-badge-juhlapesu.png';
import juhlapesuBadgeAppleAppStore from '../assets/img/appstore-badge-juhlapesu.svg';
import landingPage from '../assets/img/landingpage.jpg';

const getImages = () => {
  switch (process.env.REACT_APP_BRAND) {
    case 'juhlapesu':
      return {
        logo: juhlapesuLogo,
        badgeGooglePlay: juhlapesuBadgeGooglePlay,
        badgeAppleAppStore: juhlapesuBadgeAppleAppStore,
      };
    case 'glimra':
    default:
      return {
        logo: glimraLogo,
        badgeGooglePlay: glimraBadgeGooglePlay,
        badgeAppleAppStore: glimraBadgeAppleAppStore,
      };
  }
};

const commonImages = {
  landingPage,
};

const IMAGES = { ...commonImages, ...getImages() };

export default IMAGES;
